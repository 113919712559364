import React, { useEffect, useState, useMemo } from 'react';
import './pagination.css';
import './options.css'
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import debounce from 'lodash.debounce';

import Options from './options';
import Table from './table';
import Pagination from './pagination';
import Loading from './loading';


export default (props) => {
  const itemsPerPage = 15;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);  
  const [itemOffset, setItemOffset] = useState(0);
  const [recordsLength, setRecordsLength] = useState(0);
  const [newSearch, setNewSearch] = useState(false);
  const [option, setOption] = useState(
    {
      region: "",
      county: "",
      roadway: "",
      direction: "",
      date: "",
      id: "",
      description: ""
    }
  )

  useEffect(() => {    
    var params = new URLSearchParams();

    params.append('offset', itemOffset);
    params.append('limit', itemsPerPage);

    if(option.region) params.append('region', option.region);
    if(option.county) params.append('county', option.county);
    if(option.roadway) params.append('roadway', option.roadway);
    if(option.id) params.append('id', option.id);
    if(option.direction) params.append('direction', option.direction);
    if(option.description) params.append('description', option.description);

    if(option.date) {
      var date = new Date(option.date);

      var month = date.getMonth() + 1;
      var day = date.getDate();
      var year = date.getFullYear();
      if(month < 10) {
        month = '0' + month;
      }
      if(day < 10) {
        day = '0' + day;
      }

      var dateString = year + '-' + month + '-' + day;
      params.append('date', dateString);
    }
    trackPromise(
      axios.post(props.endPoint, params).then(response => {
        if (response.data.count !== recordsLength) {
          setPageCount(Math.ceil(response.data.count / itemsPerPage));
          setRecordsLength(response.data.count);
        }
        setCurrentItems(response.data.crashes);
      }).catch(response => {
        console.log(response);
      })
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, newSearch, props.endPoint]);

  useEffect(() => {
    setCurrentItems([]);
    setItemOffset(0);
    setCurrPage(0);
  }, [props.endPoint]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % recordsLength;
    setCurrPage(event.selected);
    setItemOffset(newOffset);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNewSearch = () => {
		setNewSearch(!newSearch);
    setCurrPage(0);
    setItemOffset(0);
  } 

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(() => {
        handleNewSearch();
      }, 1000),
    [handleNewSearch],
  );

  return (
    <div className="pagination-list pagination-dashboard">
      <h1>
        {props.title}
      </h1>
      <Options
        option={option}
        setOption={setOption}
        newSearch={debouncedChangeHandler}
        title={props.title}
        color={props.color}
      />
      <Table
        data={currentItems}
      />
      <Loading />
      <Pagination
        pageCount={pageCount}
        currPage={currPage}
        handlePageClick={handlePageClick}
        itemOffset={itemOffset}
        itemsPerPage={itemsPerPage}
        recordsLength={recordsLength}
        currentItems={currentItems}
      />
    </div>
  );
};
