import React, { useEffect, useState } from 'react';
import '../records/pagination.css';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import Options from './options.js';
import Table from './table.js';
import Pagination from '../records/pagination';
import Loading from '../records/loading';
import Expanded from './expanded';


export default (props) => {
  const itemsPerPage = 15;
  const [newSearch, setNewSearch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [recordsLength, setRecordsLength] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState();

  const [data, setData] = useState([
		{
			"agency_type_txt": "",
			"case_number": "",
			"checked": "",
			"commercial_vehicle_ind": "",
			"county_txt": "",
			"crash_date": "",
			"crash_number": "",
			"data_source": "",
			"id_incr": "",
			"injsever": "",
			"last_updated": "",
			"latitude": "",
			"longitude": "",
			"number_of_injured": "",
			"number_of_killed": "",
			"number_of_pedestrians": "",
			"number_of_serious_injuries": "",
			"on_roadway_name": "",
			"possible_matches": "",
			"refdirect": "",
			"state_road_number": "",
			"text": "",
			"total_drivers": "",
			"us_road_number": "",
			"xid": ""
		}
  ]);

	const [dataCount, setDataCount] = useState({
		"commercial_count": 0,
		"severity_count_1": 0,
		"severity_count_2": 0,
		"severity_count_3": 0,
		"severity_count_4": 0,
		"severity_count_5": 0,
    "severity_count_6": 0,
    "not_checked": 0,
    "no_crash": 0,
    "possible_crash": 0,
    "confirmed_crash": 0,
    "being_checked": 0,
	});

  const urlParams = new URLSearchParams(window.location.search);

	const [options, setOptions] = useState({
    "commercial": urlParams.get('commercial') === "true" ? true : false,
		"checked": urlParams.get('checked') || "",
		"severity": urlParams.get('severity') ? urlParams.get('severity').split(',').map(severity => parseInt(severity)) : [],
		"id": urlParams.get('id') || "",
	});

	const dataCountFields = ['count', 'checked_count', 'commercial_count', 'severity_count_1', 'severity_count_2', 'severity_count_3', 'severity_count_4', 'severity_count_5', 'severity_count_6', 'not_checked', 'no_crash', 'possible_crash', 'confirmed_crash', 'being_checked'];

  useEffect(() => {
    var params = new URLSearchParams();
    params.append('offset', itemOffset);
	  params.append('count', recordsLength > 0 ? 0 : 1);
    params.append('limit', itemsPerPage);
    params.append('severity', options.severity.length === 0 ? 7 : options.severity.join(','));
    params.append('commercial', options.commercial ? "Y" : "N");
    params.append('id', options.id);
    params.append('checked', options.checked ? options.checked : "NCHECK");
    params.append('severity_count_1', options.severity.find(severity => severity === 1) && !dataCount.severity_count_1 ? 1 : 0);
    params.append('severity_count_2', options.severity.find(severity => severity === 2) && !dataCount.severity_count_2 ? 1 : 0);
    params.append('severity_count_3', options.severity.find(severity => severity === 3) && !dataCount.severity_count_3 ? 1 : 0);
    params.append('severity_count_4', options.severity.find(severity => severity === 4) && !dataCount.severity_count_4 ? 1 : 0);
    params.append('severity_count_5', options.severity.find(severity => severity === 5) && !dataCount.severity_count_5 ? 1 : 0);
    params.append('severity_count_6', options.severity.find(severity => severity === 6) && !dataCount.severity_count_6 ? 1 : 0);
    params.append('commercial_count', options.commercial && !dataCount.commercial_count ? 1 : 0);
    params.append('not_checked', options.checked === "not checked" && !dataCount.not_checked ? 1 : 0);
    params.append('no_crash', options.checked === "no crash" && !dataCount.no_crash ? 1 : 0);
    params.append('possible_crash', options.checked === "possible crash" && !dataCount.possible_crash ? 1 : 0);
    params.append('confirmed_crash', options.checked === "confirmed crash" && !dataCount.confirmed_crash ? 1 : 0);
    params.append('being_checked', options.checked === "being checked" && !dataCount.being_checked ? 1 : 0);

    // console.log(JSON.parse('{"' + decodeURI(params.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'));

    trackPromise(
      axios.post("https://pktraffic.com/api/ssogis.php", params).then(response => {
				if (response.data.data) {
          setData(response.data.data.map((record) => {
              let parsed;
              try {
                  parsed = record.data_source.replace(/'/g, '"').replace(/None/g, 'null').replace(/nan/g, 'null'); 
                  parsed = parsed.replace(/""/g, '"');               

                  return {
                      ...record,
                      data_source: JSON.parse(parsed),
                  };
              } catch (error) {
                  console.error(`Failed to parse JSON string: ${parsed}`);
                  console.error(error);
                  return record;
              }
          }));
      }

				setDataCount(prevDataCount => {
					const newDataCount = { ...prevDataCount };
					dataCountFields.forEach(field => {
						if (response.data[field]) {
							newDataCount[field] = response.data[field];
						}
					});
					return newDataCount;
				});
        if (response.data.count) {
          setPageCount(Math.ceil(response.data.count / itemsPerPage));
          setRecordsLength(response.data.count);
        }

      }).catch(response => {
        console.log(response);
      })
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, newSearch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const optionsMap = {
      commercial: 'commercial',
      checked: 'checked',
      severity: 'severity',
      id: 'id',
      limit: 'limit',
      offset: 'offset'
    };

    for (const [option, key] of Object.entries(optionsMap)) {
      if (option === 'severity') {
        if (options[option].length) searchParams.set(key, options[option].join(','));
        else if (searchParams.has(key)) searchParams.delete(key);
      } else {
        if (options[option]) searchParams.set(key, options[option]);
        else if (searchParams.has(key)) searchParams.delete(key);
      }
    }
  
    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
  }, [options]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % recordsLength;
    setCurrPage(event.selected);
    setItemOffset(newOffset);
  };

  const newSearchHandler = () => {
    setItemOffset(0);
    setCurrPage(0);
    setPageCount(0);
    setRecordsLength(0);
    setNewSearch(!newSearch);
  };

  const newSearchHandlerExpanded = () => {
    setNewSearch(!newSearch);
  };

  return (
    <div className="pagination-list pagination-dashboard">
      <Expanded
        show={expanded}
        setShow={setExpanded}
        data={data.find(record => record.xid === expandedId)}
        newSearch={newSearchHandlerExpanded}
      />
      <Options
        options={options}
        setOptions={setOptions}
        count={dataCount}

        newSearch={newSearchHandler}
      />
      <Table
        data={data}
        handleExpand={(expandedId) => {
          setExpandedId(expandedId);
          setExpanded(true);
        }}
      />
      <Loading />
      <Pagination
        pageCount={pageCount}
        currPage={currPage}
        handlePageClick={handlePageClick}
        itemOffset={itemOffset}
        itemsPerPage={itemsPerPage}
        recordsLength={recordsLength}
        currentItems={data}
      />
    </div>
  );
};
