import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';

export const ErrorCard = (props) => {
  return (
    <>
      {props.errorStats.length > 1 ? Object.keys(props.errorStats[0]).map(function(element) {
        if (element !== "id_incr" && element !== "date") {
          return(
            <OverlayTrigger
              key={element}
              placement={"top"}
              overlay={
                <Tooltip>
                  {getErrorMessage(element)}
                </Tooltip>
              }
            >
              <Col xs={12} sm={6} xl={3} className="mb-4">
                <Card border="light" className="shadow-sm counter-widget-text" style={{width: "100%"}}>
                  <Card.Body>
                    <Row className="d-block d-xl-flex align-items-center" style={{height: "100%"}}>
                      <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                        <div className={`icon icon-shape icon-md icon-shape-secondary rounded me-4 me-sm-0 hide-icon`}>
                          <FontAwesomeIcon icon={faChartLine} style={{fontSize: "30px"}} className={`hide-icon`}/>
                        </div>
                        <div className="d-sm-none cards-mobile">
                          <h5>{element}</h5>
                          <h3 className="mb-1" style={{textAlign: "center"}}>{props.errorStats[0][element]}</h3>
                        </div>
                      </Col>
                      <Col xs={12} xl={7} className="px-xl-0 cards-mobile" style={{textAlign: "left"}}>
                        <div className="d-none d-sm-block">
                          <h5>{element}</h5>
                          <h3 className="mb-1">{props.errorStats[0][element]}</h3>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
          )
        } else {
          return null;
        }
      }) : null}
    </>
  );
};

function getErrorMessage(errorCode) {
  // SSL Certificate -> <Strong>SSL Certificate Verification failed> - The SSL certificate verification failed. The local system may not have the necessary root certificates installed. Please contact your system administrator.
  // code_403 -> 403> - Forbidden. The server understood the request, but is refusing to fulfill it. Authorization will not help and the request SHOULD NOT be repeated.
  // code_404 -> 404> - Not Found. The server has not found anything matching the Request-URI. No indication is given of whether the condition is temporary or permanent.
  // code_503 -> 503> - Service Unavailable. The server is currently unable to handle the request due to a temporary overloading or maintenance of the server.
  // errno_2 -> 2> - No such file or directory. A component of the path prefix of pathname did not name an existing file or pathname was an empty string.
  // no_error -> 0> - No error. The operation completed successfully.
  // remote_closed -> Remote Closed> - The remote server closed the connection.
  // timed_out -> Timed Out> - The connection timed out.
  // winerror_10054 -> 10054> - Connection reset by peer. An existing connection was forcibly closed by the remote host.
  // winerror_10060 -> 10060> - Connection timed out. A connection attempt failed because the connected party did not properly respond after a period of time, or established connection failed because connected host has failed to respond.
  // winerror_10061 -> 10061> - Connection refused. No connection could be made because the target machine actively refused it.

  const errorMessagesMapping = {
    "id_incr": "Error incrementing the ID",
    "code_503": "503 - Service Unavailable. The server is currently unable to handle the request due to a temporary overloading or maintenance of the server.",
    "code_403": "403 - Forbidden. The server understood the request, but is refusing to fulfill it. Authorization will not help and the request SHOULD NOT be repeated.",
    "code_404": "404 - Not Found. The server has not found anything matching the Request-URI. No indication is given of whether the condition is temporary or permanent.",
    "winerror_10061": "10061 - Connection refused. No connection could be made because the target machine actively refused it.",
    "winerror_10060": "10060 - Connection timed out. A connection attempt failed because the connected party did not properly respond after a period of time, or established connection failed because connected host has failed to respond.",
    "winerror_10054": "10054 - Connection reset by peer. An existing connection was forcibly closed by the remote host.",
    "ssl_992": "SSL 992 - [SSL: CERTIFICATE_VERIFY_FAILED] certificate verify failed: unable to get local issuer certificate",
    "ssl_975": "SSL 975 - The handshake operation timed out",
    "errno_2": "errno 2 - No such file or directory. A component of the path prefix of pathname did not name an existing file or pathname was an empty string.",
    "remote_closed": "Remote Closed - The remote server closed the connection.",
    "timed_out": "Timed Out - The connection timed out.",
    "unsupported_url": "Unsupported URL",
    "max_retries": "The maximum number of retries has been reached",
  }  

  return errorMessagesMapping[errorCode];
}
