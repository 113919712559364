import React, { useEffect, useState } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { } from './firebase.js';
import Model from './model.js';

import Navigation from './Navigation/navigation.js';
import Pagination from './Pagination/pagination.js';
import Homepage from './Home/homepage.js';
import NotFound from './NotFound/notFound.js';
import Dashboard from './Dashboard/dashboard';

import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Login from './Login/login';

const model = new Model();

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [user, setUser] = useState(null);
  // eslint-disable-next-line
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const authVar = getAuth();
    setAuth(authVar);

    setPersistence(authVar, browserLocalPersistence).then(() => {
      onAuthStateChanged(authVar, (user) => {
          if (user) {
            setUser(user);
            setLoggedIn(true);
            setLoading(false);

            if (window.location.pathname === '/login') {
              window.location.href = '/dashboard';
            }
          } else {
            setLoggedIn(false);
            setLoading(false);
  
            if (window.location.pathname.includes('/dashboard')) {
              window.location.href = '/login';
            }
          }
      });
    });
  }, []);

  const DefaultContainer = () => (
    <>
    <Navigation />
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/records" element={<Pagination endPoint={'https://pktraffic.com/api/records.php'}/>} />
    </Routes>
    </>
  );

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {loggedIn && !loading ? (
            <>
            <Route path="/login" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/*" element={<Dashboard model={model} />} />
            <Route path="*" element={<DefaultContainer />}/>
            </>
          ) : (
            <>
            <Route path="/login" element={<Login model={model} />} />
            <Route path="/dashboard/*" element={<></>} />
            <Route path="*" element={<DefaultContainer />}/>
            </>
          )}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
