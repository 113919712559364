
import React, { useState, useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChartPie, faCog, faSignOutAlt, faTimes, faDatabase, faVideo, faDigitalTachograph, faServer, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import './sidebar.css'

import { Routes } from "./routes";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const [image, setImage] = useState(props.model.getUserImage());
  const [name, setName] = useState(props.model.getUserName());

  let navigate = useNavigate();
  
  useEffect(() => {
    const obs = () => {
      setImage(props.model.getUserImage());
      setName(props.model.getUserName());
    };

    props.model.addObserver(obs);
    return () => {
      props.model.removeObserver(obs);
    };
  }, [props.model]);

  const handleSignOut = async (e) => {
    e.preventDefault();

    await props.model.signOut();
    navigate('/login')
  };

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={image} className="card-img-top rounded-circle border-white" style={{height: "100%"}}/> 
                </div>
                <div className="d-block">
                  <h6>{name ? `Hi, ${name}` : ""}</h6>
                  <Button variant="secondary" size="xs" onClick={e => handleSignOut(e)} className="text-dark" style={{float: "left"}}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title="System Records" link={Routes.SystemRecords.path} icon={faDatabase} />
              <NavItem title="FL511 Records" link={Routes.FL511Records.path} icon={faDatabase} />
              <NavItem title="Error Records" link={Routes.ErrorRecords.path} icon={faDigitalTachograph} />
              <NavItem title="Server Records" link={Routes.ServerStatusRecords.path} icon={faServer} />
              <NavItem title="SSOGIS Explorer" link={Routes.SSOGIS.path} icon={faExclamation} />
              <NavItem external title="Videos" link="https://app.plex.tv/desktop/#!/media/8b969e13eca621fec46ccc5eea8619e9a4a65a80/com.plexapp.plugins.library?source=1" target="_blank" icon={faVideo} />
              <NavItem title="Raw Data" link={Routes.RawData.path} badgeText="In Progress" icon={faDatabase} />
              <NavItem title="Other" link={Routes.Other.path} badgeText="In Progress" icon={faArrowRight} />

              <Dropdown.Divider className="my-3 border-indigo" />

              <Button variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faCog} className="me-1" /> Settings</Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
