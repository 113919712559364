import React from "react";
import { Card, Table } from '@themesberg/react-bootstrap';

export default(props) => {
  const TableRow = (props) => {
    return (
      <tr>
        <td>{props.ID}</td>
        <td>{dateFixer(props.date)}</td>
        <td>{props.camera_count}</td>
        <td>{props.ram_usage}</td>
        <td>{props.disk_usage}</td>
        <td>{props.cpu_usage}</td>
        <td>{props.download_speed} Mbps</td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4" style={{width: "100%"}}>
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Date</th>
              <th className="border-0">Camera Count</th>
              <th className="border-0">RAM Usage</th>
              <th className="border-0">Disk Usage</th>
              <th className="border-0">CPU Usage</th>
              <th className="border-0">Download Speed</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map(pt => <TableRow key={`page-traffic-${pt.ID}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

function dateFixer(date) {
	return date.slice(0, -10);
}