import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import "./counterWidget.css"


export const CounterWidget = (props) => {
  const [crashesMonthCompare, setCrashesMonthCompare] = useState(0);

  useEffect(() => {
    setCrashesMonthCompare(compareLast30Days(props.crashes))
  }, [props.crashes]);

  const percentageIcon = crashesMonthCompare < 0 ? faAngleDown : faAngleUp;
  const percentageColor = crashesMonthCompare < 0 ? "text-danger" : "text-success";

  return (
    <Card border="light" className="shadow-sm counter-widget-text" style={{width: "100%", height: "100%"}}>
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center" style={{height: "100%"}}>
          <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={`icon icon-shape icon-md icon-shape-secondary rounded me-4 me-sm-0 hide-icon`}>
              <FontAwesomeIcon icon={faChartLine} style={{fontSize: "30px"}} className={`hide-icon`}/>
            </div>
            <div className="d-sm-none cards-mobile">
              <h5>{props.category}</h5>
              <h3 className="mb-1" style={{textAlign: "center"}}>{totalCrashes(props.crashesPreview)}</h3>
            </div>
          </Col>
          <Col xs={12} xl={7} className="px-xl-0 cards-mobile" style={{textAlign: "left"}}>
            <div className="d-none d-sm-block">
              <h5>{props.category}</h5>
              <h3 className="mb-1">{totalCrashes(props.crashesPreview)}</h3>
            </div>
            <small>{getDates(props.period)}</small>
            <div className="small mt-2">
              <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
              <span className={`${percentageColor} fw-bold`}>
                {crashesMonthCompare.toFixed(2)}%
              </span> Since last month
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function compareLast30Days(data) {
  if (data.length === 0) {
    return 0;
  }

  let crashes30Days = 0;
  for (let i = 0; i < 30; i++) {
    crashes30Days += parseInt(data[i].count);
  }

  let crashes30To60Days = 0;
  for (let i = 30; i < 60; i++) {
    crashes30To60Days += parseInt(data[i].count);
  }  
  
  return ((crashes30Days - crashes30To60Days) / crashes30To60Days) * 100;
}

function getDates(period) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];

  const d = new Date();
  const currentDate = monthNames[d.getMonth()] + " " + d.getDate()

  // The second date is the current date minus the period. Period is a integer
  // that represents the number of days to subtract from the current date.
  const d2 = new Date(d.setDate(d.getDate() - period));
  const previousDate = monthNames[d2.getMonth()] + " " + d2.getDate();

  return previousDate + " - " + currentDate;
}

function totalCrashes(data) {
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    total += parseInt(data[i].count);
  }
  return total;
}
