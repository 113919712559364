import React from 'react';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export const SystemComparisonGraph = (props) => {
  return (
    <Card className="shadow-sm" style={{width: "100%", backgroundColor: "white"}}>
      <Card.Header className="d-flex flex-row align-items-center flex-0" style={{textAlign: "left"}}>
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            System Comparison
          </h5>
          <small className="fw-bold mt-2">
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#1C998B"}/> RAM Usage</span>
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#4DBEDF"}/> Disk Usage</span>
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#1899C0"}/> CPU Usage</span>
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#F7B924"}/> Download Speed</span>
          </small>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart data={getDataPeriod(props.data, props.period)}/>
      </Card.Body>
    </Card>
  );
};

const SalesValueChart = (props) => {
  const data = {
    labels: props.data.map(item => item.date).reverse(),
    series: [
      props.data.map(item => parseInt(item.ram_usage.slice(0, -1))).reverse(),
      props.data.map(item => parseInt(item.cpu_usage.slice(0, -1))).reverse(),
      props.data.map(item => parseInt(item.disk_usage.slice(0, -1))).reverse(),
      props.data.map(item => parseInt(item.download_speed.slice(0, -1))).reverse()
    ]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: false,
    axisX: {
      position: 'left',
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return index % (props.data.length / 10) < 1 ? dateFixer(value) : null;
      }
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: true,
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-double-octave" />
  );
};

function dateFixer(date) {
  let dateArray = date.split(" ")[0].split("-");
  return dateArray[1] + "/" + dateArray[2];
}


function getDataPeriod(data, period) {
  if (data.length === 0) {
    return [];
  }

  let now = new Date();
  const backdate30Days = new Date(now.setDate(now.getDate() - period));

  let returnData = [];

  for (let i = 0; i < data.length; i++) {
    if (new Date(data[i].date) > backdate30Days) {
      returnData.push(data[i]);
    }
  }

  return returnData;
}
