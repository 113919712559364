import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import './errorGraph.css';

export const ErrorGraph = (props) => {
	const elementsToColors = [
    { element: "max_retries", color: "#ff7f0e" },
    { element: "code_403", color: "#2ca02c" },
    { element: "code_404", color: "#9467bd" },
    { element: "code_503", color: "#8c564b" },
    { element: "errno_2", color: "#e377c2" },
    { element: "remote_closed", color: "#1f77b4" },
    { element: "ssl_975", color: "#bcbd22" },
    { element: "ssl_992", color: "#17becf" },
    { element: "timed_out", color: "#1f77b4" },
    { element: "unsupported_url", color: "#d62728" },
    { element: "winerror_10054", color: "#aec7e8" },
    { element: "winerror_10060", color: "#ffbb78" },
    { element: "winerror_10061", color: "#98df8a" }
  ];  

  return (
    <Card className="shadow-sm" style={{width: "100%", backgroundColor: "white"}}>
      <Card.Header className="d-flex flex-row align-items-center flex-0" style={{textAlign: "left"}}>
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            Error Codes
          </h5>
          <small className="fw-bold mt-2">
						{props.errorStats.length > 0 ? Object.keys(props.errorStats[0]).map(function(element) {
							if (element !== "id_incr" && element !== "date") {

                var colorPair = elementsToColors.find(item => item.element === element);
                if (colorPair === undefined) {
                  colorPair = { element: element, color: "#000000" };
                }

								return(
									<span key={`span-graph-${element}`} className="me-2"><FontAwesomeIcon icon={faSquare} color={colorPair.color}/> {element} </span>
								);
							} else {
								return null;
							}
						}) : null}
          </small>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
			{props.errorStats.length > 0 ? <SalesValueChart error={getDataPeriod(props.errorStats, props.period)} /> : null}
      </Card.Body>
    </Card>
  );
};

const SalesValueChart = (props) => {
  const data = {
    labels: props.error.map(item => item.date).reverse(),
    series: dataFixer(props.error)
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: false,
    axisX: {
      position: 'left',
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return index % (props.error.length / 10) < 1 ? dateFixer(value) : null;
      }
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: true,
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-double-octave error-graph" />
  );
};

function dataFixer(errorDataOriginal) {
	// Return a array of arrays. Each inner array should be the property values of the errorData
  const errorData = JSON.parse(JSON.stringify(errorDataOriginal));

  // Sort the properties of the objects by the their name
  for (let i = 0; i < errorData.length; i++) {
    errorData[i] = Object.fromEntries(
      Object.entries(errorData[i]).sort()
    );
  }

  // Remove both the date and id_incr properties
  for (let i = 0; i < errorData.length; i++) {
    // Remove the date property with the delete keyword and the id_incr property with the delete operator
    delete errorData[i].date;
    delete errorData[i].id_incr;
  }

	// Get the keys of the first item in the array
	const keys = Object.keys(errorData[0]);

	// Create an array of arrays
	const data = [];
	for (let i = 0; i < keys.length; i++) {
		data.push([]);
	}

	// For each item in the array
	for (let i = 0; i < errorData.length; i++) {
		// For each key in the item
		for (let j = 0; j < keys.length; j++) {
			// Add the value of the key to the array
			data[j].push(errorData[i][keys[j]]);
		}
	}

  // For each array in the array of arrays, reverse it
  for (let i = 0; i < data.length; i++) {
    data[i].reverse();
  }

	return data;
}

function dateFixer(date) {
  // Check if undefined
  if (date === undefined) {
    return null;
  }
	const dateArray = date.split(" ")[0].split("-");
	const month = dateArray[1];
	const day = parseInt(dateArray[2]) + 1;
	return month + "/" + day;
}

function getDataPeriod(data, period) {
  if (data.length === 0) {
    return [];
  }

  let now = new Date();
  const backdate30Days = new Date(now.setDate(now.getDate() - period));

  let returnData = [];

  for (let i = 0; i < data.length; i++) {
    if (new Date(data[i].date) > backdate30Days) {
      returnData.push(data[i]);
    }
  }

  return returnData;
}
