import React, { useRef, useEffect } from "react";
import { Card, Table } from '@themesberg/react-bootstrap';
import { Button, Dropdown, Checkbox, Label } from "semantic-ui-react";
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

export default(props) => {
	const dropdownMultipleRef = useRef(null);
	const [displayMore, setDisplayMore] = React.useState(false);
	const [data, setData] = React.useState({});
	const [crashData, setCrashData] = React.useState([]);
	const [checked, setChecked] = React.useState("not checked");
	const [comment, setComment] = React.useState("");

	const checkedOptions = [
		{ key: "not_checked", text: 'Not Checked', value: "not checked" },
		{ key: "no_crash", text: 'No Crash', value: "no crash" },
		{ key: "possible_crash", text: 'Possible Crash', value: "possible crash" },
		{ key: "confirmed_crash", text: 'Confirmed Crash', value: "confirmed crash" },
		{ key: "being_checked", text: 'Being Checked', value: "being checked" },
	];

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownMultipleRef.current && !dropdownMultipleRef.current.contains(event.target)) {
				props.setShow(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
 	});

	useEffect(() => {
		if (displayMore && props.data) {
			const { data_source, ...rest } = props.data;
			const result = { ...rest, ...data_source };
			setData(result);
		} else if (props.data) {
			const data = { ...props.data };
			delete data.data_source;
			setData(data);
		}
	}, [props.data, displayMore]);

	useEffect(() => {
		if (props.data && props.data.possible_matches) {
			setComment(props.data.text ? props.data.text : "")
			setChecked(props.data.checked ? props.data.checked : "not checked")
			var params = new URLSearchParams();
			// eslint-disable-next-line
			params.append('crash_ids', props.data.possible_matches.replace(/[\[\]']+/g,''));

			trackPromise(
				axios.post("https://pktraffic.com/api/specific_crash.php", params).then(response => {
					setCrashData(response.data.crashes);
			}));
		}
	}, [props.data,]);

	const displayData = (data, numColumns = 3) => {	
		delete data.data_source;

		const columns = [];
		const keys = Object.keys(data);
		const numKeysPerColumn = Math.ceil(keys.length / numColumns);
	
		for (let i = 0; i < numColumns; i++) {
			const column = {};
			const columnKeys = keys.splice(0, numKeysPerColumn);
	
			columnKeys.forEach(key => {
				column[key] = data[key];
			});
	
			columns.push(column);
		}
	
		const rows = [];
	
		for (let i = 0; i < numKeysPerColumn; i++) {
			const rowCells = [];
	
			for (let j = 0; j < numColumns; j++) {
				const column = columns[j];
				const key = Object.keys(column)[i];
				const value = column[key];

				if (key === "start_time" || key === "last_updated") {
					rowCells.push(
						<React.Fragment key={key}>
							<td style={{ fontWeight: "bold" }}>{key}</td>
							<td>{value ? value.slice(0, 16) : ""}</td>
						</React.Fragment>
					);
				} else if (key) {
					rowCells.push(
						<React.Fragment key={key}>
							<td style={{ fontWeight: "bold" }}>{key}</td>
							<td>{value}</td>
						</React.Fragment>
					);
				} else {
					rowCells.push(<td key={j}></td>);
					rowCells.push(<td key={`${j}-empty`}></td>);
				}
			}
	
			rows.push(<tr key={i}>{rowCells}</tr>);
		}
	
		return rows;
	};

  return (
		<>
		{props.show && props.data ? (
			<div className="ssogis-expanded">
				<div className="ssogis-expanded-content" ref={dropdownMultipleRef}>
					<Button
						icon="close"
						onClick={() => props.setShow(false)}
						style={{float: "right"}}
					/>
					<h1>
						Case: {props.data.xid}
					</h1>
					<div className="ssogis-expanded-content-options">
						<div className="ssogis-expanded-content-options-label-wrapper">
							<Label className="ssogis-expanded-content-options-label">
								Enter data
							</Label>
						</div>
						<Dropdown
							className="ssogis-expanded-content-options-dropdown"
							placeholder="Checked Status"
							fluid
							selection
							value={checked ? checked : props.data.checked}
							options={checkedOptions}
							onChange={(e, { value }) => {
								setChecked(value);
							}}
						/>
						<input
							type={"text"}
							className="ssogis-expanded-content-options-textarea"
							placeholder="Enter a comment"
							onChange={(e) => {
								setComment(e.target.value);
							}}
							value={comment}
						/>
						<Button
							className="ssogis-expanded-content-options-button"
							color="blue"
							onClick={() => {
								const params = new URLSearchParams();
								params.append('xid', props.data.xid);
								params.append('checked', checked);
								params.append('comment', comment);
								trackPromise(
									axios.post("https://pktraffic.com/api/update_ssogis.php", params).then(response => {
										props.newSearch();
								}));
							}}
						>
							Submit
						</Button>
					</div>
					<Card border="light" className="shadow-sm mb-4" style={{width: "100%"}}>
						<Card.Header>
							<h3 className="mb-0">Case Details</h3>
						</Card.Header>
						<Card.Body className="pb-0">
							<Checkbox
								className="ssogis-expanded-content-checkbox"
								label="Display More"
								onChange={() => setDisplayMore(!displayMore)}
							/>
							<Table responsive className="table-nowrap rounded mb-0 tableExpanded">
								<tbody>
									{displayData(data, 3)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					<Card border="light" className="shadow-sm mb-4" style={{width: "100%"}}>
						<Card.Header>
							<h2 className="mb-0">Possible Matches</h2>
						</Card.Header>
						<Card.Body className="pb-0">
							<div className="ssogis-expanded-content-possible-matches">
								{crashData && crashData.map((crash, index) => {
									return (
										<div className="ssogis-expanded-content-possible-matches-column" key={index}>
											<h4>
												Case: {crash.case_id}
											</h4>
											<Table responsive className="rounded mb-0 tableExpanded">
												<tbody>
													{displayData(crash, 1)}
												</tbody>
											</Table>
										</div>
									)
								})}
							</div>
						</Card.Body>
					</Card>
				</div>
			</div>
		) : null
		}
    </>
  );
};
