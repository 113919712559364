import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const CameraCountGraph = (props) => {
  const [monthCompare, setMonthCompare] = useState(0);

  useEffect(() => {
    setMonthCompare(compareLast30Days(props.data))
  }, [props.data]);

  const percentageIcon = monthCompare < 0 ? faAngleDown : faAngleUp;
  const percentageColor = monthCompare < 0 ? "text-danger" : "text-success";
  return (
    <Card className="shadow-sm" style={{width: "100%", backgroundColor: "white"}}>
      <Card.Header className="d-flex flex-row align-items-center flex-0" style={{textAlign: "left"}}>
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            Camera Count
          </h5>
          <div className="small mt-2">
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={`${percentageColor} fw-bold`}>
              {monthCompare.toFixed(2)}%
            </span> Since last month
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart data={getDataPeriod(props.data, props.period)}/>
      </Card.Body>
    </Card>
  );
};

const SalesValueChart = (props) => {

  const data = {
    labels: props.data.map(item => item.date).reverse(),
    series: [props.data.map(item => item.camera_count).reverse()]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: false,
    axisX: {
      position: 'left',
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return index % (props.data.length / 10) < 1 ? dateFixer(value) : null;
      }
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: true,
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-double-octave" />
  );
};

function compareLast30Days(data) {
  if (data.length === 0) {
    return 0;
  }

  let now = new Date();
  const backdate30Days = new Date(now.setDate(now.getDate() - 30));
  const backdate60Days = new Date(now.setDate(now.getDate() - 60));

  let crashes30Days = 0;
  for (let i = 0; i < data.length; i++) {
    if (new Date(data[i].date) > backdate30Days) {
      crashes30Days += parseInt(data[i].camera_count);
    }
  }

  let crashes30To60Days = 0;
  for (let i = 0; i < data.length; i++) {
    let date = new Date(data[i].date);
    if (date > backdate60Days && date < backdate30Days) {
      crashes30To60Days += parseInt(data[i].camera_count);	
    }
  }

  return ((crashes30Days - crashes30To60Days) / crashes30To60Days) * 100;
}

function dateFixer(date) {
  let dateArray = date.split(" ")[0].split("-");
  return dateArray[1] + "/" + dateArray[2];
}

function getDataPeriod(data, period) {
  if (data.length === 0) {
    return [];
  }

  let now = new Date();
  const backdate30Days = new Date(now.setDate(now.getDate() - period));

  let returnData = [];

  for (let i = 0; i < data.length; i++) {
    if (new Date(data[i].date) > backdate30Days) {
      returnData.push(data[i]);
    }
  }

  return returnData;
}
