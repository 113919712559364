import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Form } from '@themesberg/react-bootstrap';

import ProfileCover from "../assets/img/profile-cover.jpg";


export default (props) => {
  const [image, setImage] = useState(props.model.getUserImage());
  
  useEffect(() => {
    const obs = () => {
      setImage(props.model.getUserImage());
    };

    props.model.addObserver(obs);
    return () => {
      props.model.removeObserver(obs);
    };
  }, [props.model]);

  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <GeneralInfoForm
            model={props.model}
          />
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget
                photo={image}
              />
            </Col>
            <Col xs={12}>
              <ChoosePhotoWidget
                model={props.model}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const ProfileCardWidget = (props) => {
  return (
    <Card border="light" className="text-center p-0 mb-4" style={{width: "100%"}}>
      <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" />
      <Card.Body className="pb-5">
        <Card.Img src={props.photo} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
      </Card.Body>
    </Card>
  );
};

const ChoosePhotoWidget = (props) => {
  const [photo, setPhoto] = useState(props.photo);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.model.setUserImage(photo);
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4" style={{width: "100%"}}>
      <Card.Body>
        <div className="align-items-center">
          <Row>
            <Col>
              <Form.Group controlId="formGroupImageUrl">
                <Form.Label>Image URL</Form.Label>
                <Form.Control required type="text" placeholder="Enter image URL" onChange={e => setPhoto(e.target.value)}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col style={{paddingTop: "20px"}}>
              <Button variant="primary" type="submit" onClick={e => handleSubmit(e)}>Submit</Button>
            </Col>
          </Row>

        </div>
      </Card.Body>
    </Card>
  );
};

const GeneralInfoForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    props.model.setUserName(firstName, lastName);
  }
  return (
    <Card border="light" className="bg-white shadow-sm mb-4" style={{width: "100%"}}>
      <Card.Body>
        <h5 className="mb-4">General information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your first name" onChange={e => setFirstName(e.target.value)}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Also your last name" onChange={e => setLastName(e.target.value)}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="name@company.com" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" placeholder="+12-345 678 910" />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Address</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" placeholder="Enter your home address" />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Number</Form.Label>
                <Form.Control type="number" placeholder="No." />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="City" />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Select state</Form.Label>
                <Form.Select id="state" defaultValue="0">
                  <option value="0">State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control type="tel" placeholder="ZIP" />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>Save All</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
