import React, { useEffect } from "react";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const HalfCircleChart = (props) => {
  const [ series, setSeries ] = React.useState([]);

  useEffect(() => {
    if (props.data && props.data.length) {
      setSeries([0, 0, parseInt(props.data.slice(0, -1)), 100 - parseInt(props.data.slice(0, -1))]);
    }

  }, [props.data]);
  
  return (
    <Card border="light" className="shadow-sm" style={{width: "100%", paddingBottom: "-5px"}}>
      <Card.Header className="text-center">
        <Row>
          <Col>
            <h4>{props.title}</h4>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="half-circle-chart">
        <Row className="d-block d-xl-flex align-items-center" style={{marginBottom: "-35%"}}>
          <CircleChart series={series} />
        </Row>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12} className="text-center">
            <h3>{props.data ? props.data.slice(0, -1) : 0}%</h3>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const CircleChart = (props) => {
  const { series = [], donutWidth = 20 } = props;

  const options = {
    donutWidth,
    donut: true,
    donutSolid: false,
    fullWidth: false,
    showLabel: false,
    startAngle: 270,
    total: 200,
  }

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={{ series }} options={{...options, plugins}} type="Pie" className="ct-golden-section" />
  );
};
