import React from "react";
import { Card, Table } from '@themesberg/react-bootstrap';

export default(props) => {
  const TableRow = (props) => {
    return (
      <tr>
				<td>{props.crash_id}</td>
				<td>{props.case_id}</td>
				<td>{props.county}</td>
				<td>{props.description}</td>
				<td>{props.direction}</td>
				<td>{dateFixer(props.start_time)}</td>
				<td>{dateFixer(props.last_updated)}</td>
				<td>{props.region}</td>
				<td>{props.roadway}</td>
				<td>{props.severity}</td>
				<td>{props.type}</td>
        <td>{props.camera_ids}</td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4" style={{width: "100%"}}>
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Case ID</th>
							<th className="border-0">County</th>
							<th className="border-0">Description</th>
							<th className="border-0">Direction</th>
							<th className="border-0">Start Time</th>
							<th className="border-0">Last Updated</th>
							<th className="border-0">Region</th>
							<th className="border-0">Roadway</th>
							<th className="border-0">Severity</th>
							<th className="border-0">Type</th>
							<th className="border-0">Camera IDs</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map(pt => <TableRow key={`page-traffic-${pt.crash_id}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

function dateFixer(date) {
	return date.slice(0, -10);
}