import React from "react";

import Sidebar from "./sidebar";
import NavbarDashboard from "./navbar";
import DashboardOverview from "./Pages/dashboardOverview";
import Records from "./Pages/records/records";
import Settings from "./Pages/settings";
import ErrorRecords from "./Pages/errorRecords/errorRecords";

import './scss/volt.scss';
import './navbar.css'
import { Route, Routes } from "react-router-dom";
import Ssogis from "./Pages/ssogis/ssogis";

function Dashboard(props) {
  return (
    <>
      <Sidebar model={props.model} />
      <main className="content navbar-dashboard">
        <NavbarDashboard model={props.model} />
        <Routes>
          <Route path="/overview" element={<DashboardOverview />} />
          <Route path="/system-records" element={<Records endPoint={'https://pktraffic.com/api/records.php'} title={"System Records"} />} />
          <Route path="/fl511-records" element={<Records endPoint={'https://pktraffic.com/api/fl511-records.php'} title={"FL511 Records"} />} />
          <Route path="/error-records" element={<ErrorRecords endPoint={"https://pktraffic.com/api/error-records.php"} error={true} />} />
          <Route path="/server-status-records" element={<ErrorRecords endPoint={"https://pktraffic.com/api/server-status-records.php"} error={false} />} />
          <Route path="/settings" element={<Settings model={props.model} />} />
          <Route path="/ssogis" element={<Ssogis />} />
          <Route path="/" element={<DashboardOverview />} />
        </Routes>
      </main>
    </>
  );
}

export default Dashboard;
