import React from "react";
import { Card, Table } from '@themesberg/react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default(props) => {
  const TableRow = (props) => {
    return (
      <tr>
        <td>{props.id_incr}</td>
        <td>{dateFixer(props.date)}</td>
        <td>{props.code_403}</td>
        <td>{props.code_404}</td>
        <td>{props.code_503}</td>
        <td>{props.winerror_10054}</td>
        <td>{props.winerror_10060}</td>
        <td>{props.winerror_10061}</td>
        <td>{props.ssl_975}</td>
        <td>{props.ssl_992}</td>
        <td>{props.remote_closed}</td>
        <td>{props.timed_out}</td>
        <td>{props.unsupported_url}</td>
        <td>{props.max_retries}</td>
        <td>{props.errno_2}</td>
      </tr>
    );
  };

  const errorHeaders = [
    "#", "Date", "Code 403", "Code 404", "Code 503",
    "Winerror 10054", "Winerror 10060", "Winerror 10061",
    "SSL 975", "SSL 992", "Remote Closed", "Timed Out",
    "Unsupported URL", "Max Retries", "Errno 2"
  ]

  return (
    <Card border="light" className="shadow-sm mb-4" style={{width: "100%"}}>
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              {errorHeaders.map((element, index) => {
                const errorKey = element.toLowerCase().replace(/ /g, "_");

                return (
                  <OverlayTrigger
                    key={index}
                    placement={"bottom"}
                    overlay={
                      <Tooltip>
                        {getErrorMessage(errorKey)}
                      </Tooltip>
                    }
                  >
                    <th className="border-0">{element}</th>
                  </OverlayTrigger>
                );
              })}           
            </tr>
          </thead>
          <tbody>
            {props.data.map(pt => <TableRow key={`page-traffic-${pt.id_incr}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

function dateFixer(date) {
  // Check if undefined
  if (date === undefined) {
    return "N/A";
  }
	return date.slice(0, -10);
}

function getErrorMessage(errorCode) {
  const errorMessagesMapping = {
    "id_incr": "Error incrementing the ID",
    "code_503": "503 - Service Unavailable. The server is currently unable to handle the request due to a temporary overloading or maintenance of the server.",
    "code_403": "403 - Forbidden. The server understood the request, but is refusing to fulfill it. Authorization will not help and the request SHOULD NOT be repeated.",
    "code_404": "404 - Not Found. The server has not found anything matching the Request-URI. No indication is given of whether the condition is temporary or permanent.",
    "winerror_10061": "10061 - Connection refused. No connection could be made because the target machine actively refused it.",
    "winerror_10060": "10060 - Connection timed out. A connection attempt failed because the connected party did not properly respond after a period of time, or established connection failed because connected host has failed to respond.",
    "winerror_10054": "10054 - Connection reset by peer. An existing connection was forcibly closed by the remote host.",
    "ssl_992": "SSL 992 - [SSL: CERTIFICATE_VERIFY_FAILED] certificate verify failed: unable to get local issuer certificate",
    "ssl_975": "SSL 975 - The handshake operation timed out",
    "errno_2": "errno 2 - No such file or directory. A component of the path prefix of pathname did not name an existing file or pathname was an empty string.",
    "remote_closed": "Remote Closed - The remote server closed the connection.",
    "timed_out": "Timed Out - The connection timed out.",
    "unsupported_url": "Unsupported URL",
    "max_retries": "Max Retries - The maximum number of retries was reached."
  }  

  return errorMessagesMapping[errorCode];
}
