import React from "react";
import "./homepage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import IntroImage from "./IntroImage.png";
import ServiceImage from "./ServiceImage.png";
import CasperProfile from "./CasperProfile.jpg";
import ScottParrProfile from "./ScottParrProfile.png";
import LinkedinLogo from "./linkedin.png";
import ResearchGateLogo from "./ResearchGateLogo.jpg";

function HomePage() {
    return (
        <>
            <div id="home" />
            <div className="top-padding" />
            <header id="header" className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5">
                            <div className="text-container">
                                <div className="section-title">
                                    Welcome to PK Traffic
                                </div>
                                <h1 className="h1-large">
                                    TRAFFIC DATA COLLECTION & ANALYTICS
                                </h1>
                                <p className="p-large">
                                    PK Traffic specializes in deep public record
                                    search of traffic related data and events to
                                    provide expert analysis and support
                                </p>
                                <a className="btn-solid-lg" href="#contact">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-7">
                            <div className="image-container">
                                <div className="image-remove">
                                    <img
                                        className="img-fluid"
                                        src={IntroImage}
                                        alt="alternative"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="details" className="basic-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-7">
                            <div className="image-container">
                                <img
                                    className="img-fluid"
                                    src={ServiceImage}
                                    alt="alternative"
                                    loading="eager"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5">
                            <div className="text-container">
                                <h2>
                                    <span>Deep Public Record Search</span>
                                    <br /> For Your Support
                                </h2>
                                <p>
                                    PK Traffic performs a deep record search of
                                    traffic related incidents through an
                                    intelligence applications that makes it easy
                                    to explore, analyze and visualize data to
                                    help support you.
                                </p>
                                <p>
                                    Our goal is to provide insight through
                                    post-processing to support safety and
                                    justice to traffic crash victims
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="aboutus" className="container-about">
                <div className="card">
                    <div className="card-photo">
                        <img src={ScottParrProfile} alt="" loading="lazy" />
                    </div>
                    <div className="card-title">
                        <h1 className="card-name">Dr. Scott Parr, Ph.D., PE</h1>
                    </div>
                    <div className="card-info">
                        <div className="card-profession">
                            <h4>Chief Executive Officer</h4>
                        </div>
                        <p className="card-about">
                            Scott is a certified Professional Engineer in
                            Transportation Engineering from the State of
                            Florida. He has a Ph.D. - Doctor of Philosophy in
                            Civil Engineering: Disaster Science and Management,
                            from Louisiana State University.
                        </p>
                        <br />
                        <br />
                        <p className="card-email">
                            Email
                            <br />
                            <a href="mailto:scott@pktraffic.com">
                                scott@pktraffic.com
                            </a>
                        </p>
                        <div className="social-box research-gate-img">
                            <a
                                className="social-icon social-icon--researchGate"
                                href="https://www.researchgate.net/profile/Scott-Parr"
                                target="_blank" rel="noopener noreferrer"
                            >
                                <img src={ResearchGateLogo} alt="alternative" />
                                <div className="tooltip">ResearchGate</div>
                                <br />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-photo">
                        <img src={CasperProfile} alt="" loading="lazy" />
                    </div>
                    <div className="card-title">
                        <h1 className="card-name">Casper Kristiansson</h1>
                    </div>
                    <div className="card-info">
                        <div className="card-profession">
                            <h4>Chief Technology Officer</h4>
                        </div>
                        <p className="card-about">
                            Casper is currently pursuing a degree in Computer
                            Science, Bachelor of Science in Engineering at the
                            KTH Royal Institute of Technology in Stockholm,
                            Sweden.
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <p className="card-email">
                            Email
                            <br />
                            <a href="mailto:casper@pktraffic.com">
                                casper@pktraffic.com
                            </a>
                        </p>
                        <div className="social-box">
                            <a
                                className="social-icon social-icon--linkedin"
                                target="_blank" rel="noopener noreferrer"
                                href="https://www.linkedin.com/in/casper-kristiansson/"
                            >
                                <img src={LinkedinLogo} alt="alternative" />
                                <div className="tooltip">LinkedIn</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="contact" className="form-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading">
                                <span>Use the following information</span>
                                <br /> To come in contact with us
                            </h2>
                            <ul className="list-unstyled li-space-lg">
                                <li>
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        color="#64C3FE"
                                    />{" "}
                                    &nbsp;P.O. BOX 470157, Lake Monroe, FL 32747
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        color="#64C3FE"
                                    />{" "}
                                    &nbsp;
                                    <a href="tel:00817202212">(321) 236-3682</a>
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        color="#64C3FE"
                                    />{" "}
                                    &nbsp;
                                    <a href="mailto:fanny@pktraffic.com">
                                        scott@pktraffic.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <p className="p-small statement">
                            Copyright © PKTraffic
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomePage;
