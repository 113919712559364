import React from "react";
import { Card, Table } from '@themesberg/react-bootstrap';
import { Button } from "semantic-ui-react";

export default(props) => {
  const checkedColors = {
      "not checked": "gray-c",
      "no crash": "red-c",
      "possible crash": "blue-c",
      "confirmed crash": "green-c",
      "being checked": "gray-c",
  }

  const TableRow = (props) => {
    return (
      <tr>
        <td>
            <Button
                onClick={() => props.handleExpand(props.xid)}
                className="ssogis-button"
                size="mini"
            >
                Expand
            </Button>
        </td>
        <td>
            <div className={`ssogis-options-table ${checkedColors[props.checked]}`} >
                {textFixer(props.checked)}
            </div>
        </td>
        <td>{props.xid}</td>
        <td>{dateFixer(props.crash_date)}</td>
        <td>{props.county_txt}</td>
        <td>{props.injsever}</td>
        <td>{props.commercial_vehicle_ind}</td>
        <td>{props.possible_matches}</td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4 table-expanded" style={{width: "100%"}}>
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0 align-items-center">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Expand</th>
              <th className="border-0">Checked</th>
              <th className="border-0">#</th>
              <th className="border-0">Date</th>
              <th className="border-0">County</th>
              <th className="border-0">Severity</th>
              <th className="border-0">Commercial</th>
              <th className="border-0">Possible Matches</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map(pt => <TableRow key={pt.xid} {...pt} handleExpand={props.handleExpand} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

function dateFixer(date) {
    if (date) {
        return date.slice(0, 16);
    }

    return date;
}

function textFixer(text) {
    if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return text;
}