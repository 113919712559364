import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
	return (
		<div className="not-found">
			<h1>404</h1>
			<h2>Page not found</h2>
			<Link to="/">Go to Homepage</Link>
		</div>
	);
}

export default NotFound;