
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Nav, Form, Image, Navbar, Dropdown, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useNavigate } from "react-router-dom";


export default (props) => {
  const [image, setImage] = useState(props.model.getUserImage());
  const [name, setName] = useState(props.model.getUserName());

  let navigate = useNavigate();
  
  useEffect(() => {
    const obs = () => {
      setImage(props.model.getUserImage());
      setName(props.model.getUserName());
    };

    props.model.addObserver(obs);
    return () => {
      props.model.removeObserver(obs);
    };
  }, [props.model]);

  const handleSignOut = async (e) => {
    e.preventDefault();

    await props.model.signOut();
    navigate('/login')
  };

  const handleSettings = (e) => {
    e.preventDefault();

    navigate('/dashboard/settings');
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 navbar-dashboard">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={image} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={(e) => handleSettings(e)}>
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={(e) => handleSignOut(e)} >
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
