import React from "react";

function Items({ currentItems }) {
	return (
		<>
			<table className="database-table main-table">
				<thead>
					<tr>
						<th className="case-id">Crash ID</th>
						<th className="county">County</th>
						<th className="description">Description</th>
						<th className="region">Region</th>
						<th className="roadway">Roadway Name</th>
						<th className="direction">Direction</th>
						<th className="date">Date</th>
					</tr>
				</thead>
				<tbody>
					{currentItems &&
						currentItems.map((item) => (
							<tr key={item.crash_id}>
								<td>{item.crash_id}</td>
								<td>{item.county}</td>
								<td>{item.description}</td>
								<td>{item.region}</td>
								<td>{item.roadway}</td>
								<td>{item.direction}</td>
								<td>{item.start_time.slice(0, 16)}</td>
							</tr>
					))}
				</tbody>
			</table>
		</>
	);
}

export default Items;
