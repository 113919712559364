export const region = [
  { key: null, value: null, text: 'Select...' },
  { key: 'Central', value: 'Central', text: 'Central' },
  { key: 'Northeast', value: 'Northeast', text: 'Northeast' },
  { key: 'Panhandle', value: 'Panhandle', text: 'Panhandle' },
  { key: 'Southeast', value: 'Southeast', text: 'Southeast' },
  { key: 'Southwest', value: 'Southwest', text: 'Southwest' },
  { key: 'Tampa Bay', value: 'Tampa Bay', text: 'Tampa Bay' },
]

export const county = [
  { key: null, value: null, text: 'Select...' },
  { key: 'Alachua', value: 'Alachua', text: 'Alachua' },
  { key: 'Baker', value: 'Baker', text: 'Baker' },
  { key: 'Bay', value: 'Bay', text: 'Bay' },
  { key: 'Brevard', value: 'Brevard', text: 'Brevard' },
  { key: 'Broward', value: 'Broward', text: 'Broward' },
  { key: 'Charlotte', value: 'Charlotte', text: 'Charlotte' },
  { key: 'Citrus', value: 'Citrus', text: 'Citrus' },
  { key: 'Clay', value: 'Clay', text: 'Clay' },
  { key: 'Collier', value: 'Collier', text: 'Collier' },
  { key: 'Columbia', value: 'Columbia', text: 'Columbia' },
  { key: 'DeSoto', value: 'DeSoto', text: 'DeSoto' },
  { key: 'Duval', value: 'Duval', text: 'Duval' },
  { key: 'Escambia', value: 'Escambia', text: 'Escambia' },
  { key: 'Flagler', value: 'Flagler', text: 'Flagler' },
  { key: 'Gadsden', value: 'Gadsden', text: 'Gadsden' },
  { key: 'Glades', value: 'Glades', text: 'Glades' },
  { key: 'Hamilton', value: 'Hamilton', text: 'Hamilton' },
  { key: 'Hardee', value: 'Hardee', text: 'Hardee' },
  { key: 'Hendry', value: 'Hendry', text: 'Hendry' },
  { key: 'Hernando', value: 'Hernando', text: 'Hernando' },
  { key: 'Highlands', value: 'Highlands', text: 'Highlands' },
  { key: 'Hillsborough', value: 'Hillsborough', text: 'Hillsborough' },
  { key: 'Holmes', value: 'Holmes', text: 'Holmes' },
  { key: 'Indian River', value: 'Indian River', text: 'Indian River' },
  { key: 'Jackson', value: 'Jackson', text: 'Jackson' },
  { key: 'Jefferson', value: 'Jefferson', text: 'Jefferson' },
  { key: 'Lake', value: 'Lake', text: 'Lake' },
  { key: 'Lee', value: 'Lee', text: 'Lee' },
  { key: 'Leon', value: 'Leon', text: 'Leon' },
  { key: 'Madison', value: 'Madison', text: 'Madison' },
  { key: 'Manatee', value: 'Manatee', text: 'Manatee' },
  { key: 'Marion', value: 'Marion', text: 'Marion' },
  { key: 'Martin', value: 'Martin', text: 'Martin' },
  { key: 'Miami-Dade', value: 'Miami-Dade', text: 'Miami-Dade' },
  { key: 'Monroe', value: 'Monroe', text: 'Monroe' },
  { key: 'N/A', value: 'N/A', text: 'N/A' },
  { key: 'Nassau', value: 'Nassau', text: 'Nassau' },
  { key: 'Okaloosa', value: 'Okaloosa', text: 'Okaloosa' },
  { key: 'Okeechobee', value: 'Okeechobee', text: 'Okeechobee' },
  { key: 'Orange', value: 'Orange', text: 'Orange' },
  { key: 'Osceola', value: 'Osceola', text: 'Osceola' },
  { key: 'Palm Beach', value: 'Palm Beach', text: 'Palm Beach' },
  { key: 'Pasco', value: 'Pasco', text: 'Pasco' },
  { key: 'Pinellas', value: 'Pinellas', text: 'Pinellas' },
  { key: 'Polk', value: 'Polk', text: 'Polk' },
  { key: 'Santa Rosa', value: 'Santa Rosa', text: 'Santa Rosa' },
  { key: 'Sarasota', value: 'Sarasota', text: 'Sarasota' },
  { key: 'Seminole', value: 'Seminole', text: 'Seminole' },
  { key: 'St. Johns', value: 'St. Johns', text: 'St. Johns' },
  { key: 'St. Lucie', value: 'St. Lucie', text: 'St. Lucie' },
  { key: 'Sumter', value: 'Sumter', text: 'Sumter' },
  { key: 'Suwannee', value: 'Suwannee', text: 'Suwannee' },
  { key: 'Taylor', value: 'Taylor', text: 'Taylor' },
  { key: 'Volusia', value: 'Volusia', text: 'Volusia' },
  { key: 'Walton', value: 'Walton', text: 'Walton' },
  { key: 'Washington', value: 'Washington', text: 'Washington' },
]

export const roadway = [
  { key: null, value: null, text: 'Select...' },
  { key: '15th St. E', value: '15th St. E', text: '15th St. E' },
  { key: '295 East Express', value: '295 East Express', text: '295 East Express' },
  { key: '295 West Express', value: '295 West Express', text: '295 West Express' },
  { key: '301', value: '301', text: '301' },
  { key: '441', value: '441', text: '441' },
  { key: '53rd Ave E', value: '53rd Ave E', text: '53rd Ave E' },
  { key: '53rd Ave W', value: '53rd Ave W', text: '53rd Ave W' },
  { key: '75 Express', value: '75 Express', text: '75 Express' },
  { key: '826 Express', value: '826 Express', text: '826 Express' },
  { key: '92', value: '92', text: '92' },
  { key: '95 Express', value: '95 Express', text: '95 Express' },
  { key: 'ALICO RD', value: 'ALICO RD', text: 'ALICO RD' },
  { key: 'Acosta Bridge', value: 'Acosta Bridge', text: 'Acosta Bridge' },
  { key: 'Airport Blvd', value: 'Airport Blvd', text: 'Airport Blvd' },
  { key: 'Alafaya Trl', value: 'Alafaya Trl', text: 'Alafaya Trl' },
  { key: 'Aloma Ave', value: 'Aloma Ave', text: 'Aloma Ave' },
  { key: 'Altamonte Dr', value: 'Altamonte Dr', text: 'Altamonte Dr' },
  { key: 'Americana Blvd', value: 'Americana Blvd', text: 'Americana Blvd' },
  { key: 'Apopka Vineland Rd', value: 'Apopka Vineland Rd', text: 'Apopka Vineland Rd' },
  { key: 'Archer Rd', value: 'Archer Rd', text: 'Archer Rd' },
  { key: 'Arlington Expressway', value: 'Arlington Expressway', text: 'Arlington Expressway' },
  { key: 'Atlantic Blvd', value: 'Atlantic Blvd', text: 'Atlantic Blvd' },
  { key: 'Avalon Rd', value: 'Avalon Rd', text: 'Avalon Rd' },
  { key: 'BEE RIDGE RD', value: 'BEE RIDGE RD', text: 'BEE RIDGE RD' },
  { key: 'BONITA BEACH RD', value: 'BONITA BEACH RD', text: 'BONITA BEACH RD' },
  { key: 'Babcock St', value: 'Babcock St', text: 'Babcock St' },
  { key: 'Back Beach Rd', value: 'Back Beach Rd', text: 'Back Beach Rd' },
  { key: 'Barnes Blvd', value: 'Barnes Blvd', text: 'Barnes Blvd' },
  { key: 'Baymeadows Rd', value: 'Baymeadows Rd', text: 'Baymeadows Rd' },
  { key: 'Bayshore Rd', value: 'Bayshore Rd', text: 'Bayshore Rd' },
  { key: 'Beach Blvd', value: 'Beach Blvd', text: 'Beach Blvd' },
  { key: 'Bee Ridge Rd', value: 'Bee Ridge Rd', text: 'Bee Ridge Rd' },
  { key: 'Belvedere Road', value: 'Belvedere Road', text: 'Belvedere Road' },
  { key: 'Beville Rd', value: 'Beville Rd', text: 'Beville Rd' },
  { key: 'Biscayne Blvd', value: 'Biscayne Blvd', text: 'Biscayne Blvd' },
  { key: 'Blanding Blvd', value: 'Blanding Blvd', text: 'Blanding Blvd' },
  { key: 'Boggy Creek Rd', value: 'Boggy Creek Rd', text: 'Boggy Creek Rd' },
  { key: 'Bonita Beach Rd', value: 'Bonita Beach Rd', text: 'Bonita Beach Rd' },
  { key: 'Brent Ln', value: 'Brent Ln', text: 'Brent Ln' },
  { key: 'Bronson Hwy', value: 'Bronson Hwy', text: 'Bronson Hwy' },
  { key: 'Bruce B Downs Blvd', value: 'Bruce B Downs Blvd', text: 'Bruce B Downs Blvd' },
  { key: 'Butler Boulevard', value: 'Butler Boulevard', text: 'Butler Boulevard' },
  { key: 'CLARK RD', value: 'CLARK RD', text: 'CLARK RD' },
  { key: 'COLLIER BLVD', value: 'COLLIER BLVD', text: 'COLLIER BLVD' },
  { key: 'CORKSCREW RD', value: 'CORKSCREW RD', text: 'CORKSCREW RD' },
  { key: 'CR-15', value: 'CR-15', text: 'CR-15' },
  { key: 'CR-189 (Log Lake Rd)', value: 'CR-189 (Log Lake Rd)', text: 'CR-189 (Log Lake Rd)' },
  { key: 'CR-191 (Garcon Point Rd)', value: 'CR-191 (Garcon Point Rd)', text: 'CR-191 (Garcon Point Rd)' },
  { key: 'CR-270A', value: 'CR-270A', text: 'CR-270A' },
  { key: 'CR-279', value: 'CR-279', text: 'CR-279' },
  { key: 'CR-314', value: 'CR-314', text: 'CR-314' },
  { key: 'CR-4146', value: 'CR-4146', text: 'CR-4146' },
  { key: 'CR-4162', value: 'CR-4162', text: 'CR-4162' },
  { key: 'CR-419', value: 'CR-419', text: 'CR-419' },
  { key: 'CR-424', value: 'CR-424', text: 'CR-424' },
  { key: 'CR-427', value: 'CR-427', text: 'CR-427' },
  { key: 'CR-431', value: 'CR-431', text: 'CR-431' },
  { key: 'CR-431B', value: 'CR-431B', text: 'CR-431B' },
  { key: 'CR-435', value: 'CR-435', text: 'CR-435' },
  { key: 'CR-439', value: 'CR-439', text: 'CR-439' },
  { key: 'CR-46A', value: 'CR-46A', text: 'CR-46A' },
  { key: 'CR-484', value: 'CR-484', text: 'CR-484' },
  { key: 'CR-502', value: 'CR-502', text: 'CR-502' },
  { key: 'CR-509', value: 'CR-509', text: 'CR-509' },
  { key: 'CR-511', value: 'CR-511', text: 'CR-511' },
  { key: 'CR-516', value: 'CR-516', text: 'CR-516' },
  { key: 'CR-527', value: 'CR-527', text: 'CR-527' },
  { key: 'CR-532', value: 'CR-532', text: 'CR-532' },
  { key: 'CR-535', value: 'CR-535', text: 'CR-535' },
  { key: 'CR-561', value: 'CR-561', text: 'CR-561' },
  { key: 'CR-582a', value: 'CR-582a', text: 'CR-582a' },
  { key: 'CR-89 (Ward Basin Rd)', value: 'CR-89 (Ward Basin Rd)', text: 'CR-89 (Ward Basin Rd)' },
  { key: 'Card Sound Road', value: 'Card Sound Road', text: 'Card Sound Road' },
  { key: 'Central Florida Pkwy', value: 'Central Florida Pkwy', text: 'Central Florida Pkwy' },
  { key: 'Champions Gate', value: 'Champions Gate', text: 'Champions Gate' },
  { key: 'Chancery Ln', value: 'Chancery Ln', text: 'Chancery Ln' },
  { key: 'Chickasaw Trl', value: 'Chickasaw Trl', text: 'Chickasaw Trl' },
  { key: 'Clarcona Ocoee Rd', value: 'Clarcona Ocoee Rd', text: 'Clarcona Ocoee Rd' },
  { key: 'Clark Rd', value: 'Clark Rd', text: 'Clark Rd' },
  { key: 'College Rd', value: 'College Rd', text: 'College Rd' },
  { key: 'Colonial Blvd', value: 'Colonial Blvd', text: 'Colonial Blvd' },
  { key: 'Columbia Blvd', value: 'Columbia Blvd', text: 'Columbia Blvd' },
  { key: 'Commercial Blvd', value: 'Commercial Blvd', text: 'Commercial Blvd' },
  { key: 'Conroy Rd', value: 'Conroy Rd', text: 'Conroy Rd' },
  { key: 'Convention Way', value: 'Convention Way', text: 'Convention Way' },
  { key: 'Conway Rd', value: 'Conway Rd', text: 'Conway Rd' },
  { key: 'Cortez Rd W', value: 'Cortez Rd W', text: 'Cortez Rd W' },
  { key: 'Curry Ford Rd', value: 'Curry Ford Rd', text: 'Curry Ford Rd' },
  { key: 'DANIELS PARKWAY', value: 'DANIELS PARKWAY', text: 'DANIELS PARKWAY' },
  { key: 'Dairy Rd', value: 'Dairy Rd', text: 'Dairy Rd' },
  { key: 'Dale Mabry Hwy', value: 'Dale Mabry Hwy', text: 'Dale Mabry Hwy' },
  { key: 'Davis Hwy', value: 'Davis Hwy', text: 'Davis Hwy' },
  { key: 'Dean Rd', value: 'Dean Rd', text: 'Dean Rd' },
  { key: 'Dirksen Dr', value: 'Dirksen Dr', text: 'Dirksen Dr' },
  { key: 'Dunlawton Ave', value: 'Dunlawton Ave', text: 'Dunlawton Ave' },
  { key: 'Dunn Ave', value: 'Dunn Ave', text: 'Dunn Ave' },
  { key: 'E Airport Blvd', value: 'E Airport Blvd', text: 'E Airport Blvd' },
  { key: 'E Industry Rd', value: 'E Industry Rd', text: 'E Industry Rd' },
  { key: 'E Lake Mary Blvd', value: 'E Lake Mary Blvd', text: 'E Lake Mary Blvd' },
  { key: 'E Mitchell Hammock Rd', value: 'E Mitchell Hammock Rd', text: 'E Mitchell Hammock Rd' },
  { key: 'E SR-50', value: 'E SR-50', text: 'E SR-50' },
  { key: 'E South St', value: 'E South St', text: 'E South St' },
  { key: 'E Sr-434', value: 'E Sr-434', text: 'E Sr-434' },
  { key: 'East-West Expy', value: 'East-West Expy', text: 'East-West Expy' },
  { key: 'Econlockhatchee Trl', value: 'Econlockhatchee Trl', text: 'Econlockhatchee Trl' },
  { key: 'Edgewater Dr', value: 'Edgewater Dr', text: 'Edgewater Dr' },
  { key: 'Ellis Rd', value: 'Ellis Rd', text: 'Ellis Rd' },
  { key: 'Emerald Coast Pkwy', value: 'Emerald Coast Pkwy', text: 'Emerald Coast Pkwy' },
  { key: 'Emerson Dr', value: 'Emerson Dr', text: 'Emerson Dr' },
  { key: 'FRUITVILLE RD', value: 'FRUITVILLE RD', text: 'FRUITVILLE RD' },
  { key: 'Fairbanks Ave', value: 'Fairbanks Ave', text: 'Fairbanks Ave' },
  { key: 'Fairfield Dr', value: 'Fairfield Dr', text: 'Fairfield Dr' },
  { key: 'Fay Blvd', value: 'Fay Blvd', text: 'Fay Blvd' },
  { key: 'First Coast Expressway', value: 'First Coast Expressway', text: 'First Coast Expressway' },
  { key: 'Flagler Drive', value: 'Flagler Drive', text: 'Flagler Drive' },
  { key: 'Flat Creek Rd', value: 'Flat Creek Rd', text: 'Flat Creek Rd' },
  { key: 'Floridas Turnpike', value: 'Floridas Turnpike', text: 'Floridas Turnpike' },
  { key: 'Floridas Turnpike Golden Glades Spur', value: 'Floridas Turnpike Golden Glades Spur', text: 'Floridas Turnpike Golden Glades Spur' },
  { key: 'Forest City Rd', value: 'Forest City Rd', text: 'Forest City Rd' },
  { key: 'Fowler Ave', value: 'Fowler Ave', text: 'Fowler Ave' },
  { key: 'Fowler St.', value: 'Fowler St.', text: 'Fowler St.' },
  { key: 'Fruitville Rd', value: 'Fruitville Rd', text: 'Fruitville Rd' },
  { key: 'Ft Brooks Rd', value: 'Ft Brooks Rd', text: 'Ft Brooks Rd' },
  { key: 'GANDY BLVD', value: 'GANDY BLVD', text: 'GANDY BLVD' },
  { key: 'GOLDEN GATE PKWY', value: 'GOLDEN GATE PKWY', text: 'GOLDEN GATE PKWY' },
  { key: 'Gandy Blvd', value: 'Gandy Blvd', text: 'Gandy Blvd' },
  { key: 'Gibsonton Drive', value: 'Gibsonton Drive', text: 'Gibsonton Drive' },
  { key: 'Goldenrod Rd', value: 'Goldenrod Rd', text: 'Goldenrod Rd' },
  { key: 'Good Homes Rd', value: 'Good Homes Rd', text: 'Good Homes Rd' },
  { key: 'Greensboro Hwy', value: 'Greensboro Hwy', text: 'Greensboro Hwy' },
  { key: 'Griffin Road', value: 'Griffin Road', text: 'Griffin Road' },
  { key: 'HARBOR VIEW ROAD', value: 'HARBOR VIEW ROAD', text: 'HARBOR VIEW ROAD' },
  { key: 'Hallandale Beach Blvd', value: 'Hallandale Beach Blvd', text: 'Hallandale Beach Blvd' },
  { key: 'Harden Blvd', value: 'Harden Blvd', text: 'Harden Blvd' },
  { key: 'Hart Bridge', value: 'Hart Bridge', text: 'Hart Bridge' },
  { key: 'Hiawassee Rd', value: 'Hiawassee Rd', text: 'Hiawassee Rd' },
  { key: 'Hoffner Ave', value: 'Hoffner Ave', text: 'Hoffner Ave' },
  { key: 'Hollywood Blvd', value: 'Hollywood Blvd', text: 'Hollywood Blvd' },
  { key: 'Howell Branch Rd', value: 'Howell Branch Rd', text: 'Howell Branch Rd' },
  { key: 'I-10', value: 'I-10', text: 'I-10' },
  { key: 'I-110', value: 'I-110', text: 'I-110' },
  { key: 'I-175', value: 'I-175', text: 'I-175' },
  { key: 'I-195', value: 'I-195', text: 'I-195' },
  { key: 'I-275', value: 'I-275', text: 'I-275' },
  { key: 'I-295 E', value: 'I-295 E', text: 'I-295 E' },
  { key: 'I-295 W', value: 'I-295 W', text: 'I-295 W' },
  { key: 'I-375', value: 'I-375', text: 'I-375' },
  { key: 'I-395', value: 'I-395', text: 'I-395' },
  { key: 'I-4', value: 'I-4', text: 'I-4' },
  { key: 'I-4 Express Lanes', value: 'I-4 Express Lanes', text: 'I-4 Express Lanes' },
  { key: 'I-595', value: 'I-595', text: 'I-595' },
  { key: 'I-75', value: 'I-75', text: 'I-75' },
  { key: 'I-75 Express Lanes', value: 'I-75 Express Lanes', text: 'I-75 Express Lanes' },
  { key: 'I-95', value: 'I-95', text: 'I-95' },
  { key: 'IMMOKALEE ROAD', value: 'IMMOKALEE ROAD', text: 'IMMOKALEE ROAD' },
  { key: 'Indiantown Road', value: 'Indiantown Road', text: 'Indiantown Road' },
  { key: 'International Dr', value: 'International Dr', text: 'International Dr' },
  { key: 'International Pkwy', value: 'International Pkwy', text: 'International Pkwy' },
  { key: 'JACARANDA BLVD', value: 'JACARANDA BLVD', text: 'JACARANDA BLVD' },
  { key: 'JOHN RINGLING CAUSEWAY', value: 'JOHN RINGLING CAUSEWAY', text: 'JOHN RINGLING CAUSEWAY' },
  { key: 'Jog Road', value: 'Jog Road', text: 'Jog Road' },
  { key: 'John Young Parkway', value: 'John Young Parkway', text: 'John Young Parkway' },
  { key: 'John Young Pkwy', value: 'John Young Pkwy', text: 'John Young Pkwy' },
  { key: 'Kathleen Rd', value: 'Kathleen Rd', text: 'Kathleen Rd' },
  { key: 'King St', value: 'King St', text: 'King St' },
  { key: 'Kings Highway', value: 'Kings Highway', text: 'Kings Highway' },
  { key: 'Kirkman Rd', value: 'Kirkman Rd', text: 'Kirkman Rd' },
  { key: 'Krome Avenue', value: 'Krome Avenue', text: 'Krome Avenue' },
  { key: 'LAUREL RD', value: 'LAUREL RD', text: 'LAUREL RD' },
  { key: 'LPGA Blvd', value: 'LPGA Blvd', text: 'LPGA Blvd' },
  { key: 'Lake City', value: 'Lake City', text: 'Lake City' },
  { key: 'Lake Mary Blvd', value: 'Lake Mary Blvd', text: 'Lake Mary Blvd' },
  { key: 'Lake Underhill Rd', value: 'Lake Underhill Rd', text: 'Lake Underhill Rd' },
  { key: 'Landstar Blvd', value: 'Landstar Blvd', text: 'Landstar Blvd' },
  { key: 'Landstreet Rd', value: 'Landstreet Rd', text: 'Landstreet Rd' },
  { key: 'Lee Rd', value: 'Lee Rd', text: 'Lee Rd' },
  { key: 'Leevista Blvd', value: 'Leevista Blvd', text: 'Leevista Blvd' },
  { key: 'Lockwood Rd', value: 'Lockwood Rd', text: 'Lockwood Rd' },
  { key: 'Luckett Road', value: 'Luckett Road', text: 'Luckett Road' },
  { key: 'MLK Jr. Blvd', value: 'MLK Jr. Blvd', text: 'MLK Jr. Blvd' },
  { key: 'MacArthur Causeway', value: 'MacArthur Causeway', text: 'MacArthur Causeway' },
  { key: 'Main Street Bridge', value: 'Main Street Bridge', text: 'Main Street Bridge' },
  { key: 'Manatee Ave', value: 'Manatee Ave', text: 'Manatee Ave' },
  { key: 'Martin Luther King Jr Pkwy', value: 'Martin Luther King Jr Pkwy', text: 'Martin Luther King Jr Pkwy' },
  { key: 'Mathews Bridge', value: 'Mathews Bridge', text: 'Mathews Bridge' },
  { key: 'Mayport Road', value: 'Mayport Road', text: 'Mayport Road' },
  { key: 'McGregor Blvd', value: 'McGregor Blvd', text: 'McGregor Blvd' },
  { key: 'Metro Pkwy', value: 'Metro Pkwy', text: 'Metro Pkwy' },
  { key: 'Metropolis Way', value: 'Metropolis Way', text: 'Metropolis Way' },
  { key: 'Miami Gardens DR', value: 'Miami Gardens DR', text: 'Miami Gardens DR' },
  { key: 'Michigan Ave', value: 'Michigan Ave', text: 'Michigan Ave' },
  { key: 'Michigan St', value: 'Michigan St', text: 'Michigan St' },
  { key: 'Midbay Bridge', value: 'Midbay Bridge', text: 'Midbay Bridge' },
  { key: 'Military Trail', value: 'Military Trail', text: 'Military Trail' },
  { key: 'Mills Ave', value: 'Mills Ave', text: 'Mills Ave' },
  { key: 'Minton Rd', value: 'Minton Rd', text: 'Minton Rd' },
  { key: 'Mitchell Hammock Rd', value: 'Mitchell Hammock Rd', text: 'Mitchell Hammock Rd' },
  { key: 'Moccasin Wallow', value: 'Moccasin Wallow', text: 'Moccasin Wallow' },
  { key: 'Monroe Rd', value: 'Monroe Rd', text: 'Monroe Rd' },
  { key: 'Montgomery Rd', value: 'Montgomery Rd', text: 'Montgomery Rd' },
  { key: 'Mtchll Hmmck', value: 'Mtchll Hmmck', text: 'Mtchll Hmmck' },
  { key: 'Murrell Rd', value: 'Murrell Rd', text: 'Murrell Rd' },
  { key: 'N Chickasaw Trl', value: 'N Chickasaw Trl', text: 'N Chickasaw Trl' },
  { key: 'N Cocoa Blvd', value: 'N Cocoa Blvd', text: 'N Cocoa Blvd' },
  { key: 'N Dean Rd', value: 'N Dean Rd', text: 'N Dean Rd' },
  { key: 'N Garland Ave', value: 'N Garland Ave', text: 'N Garland Ave' },
  { key: 'N Hiawassee Rd', value: 'N Hiawassee Rd', text: 'N Hiawassee Rd' },
  { key: 'N John Young Pkwy', value: 'N John Young Pkwy', text: 'N John Young Pkwy' },
  { key: 'N Orange Blossom Trl', value: 'N Orange Blossom Trl', text: 'N Orange Blossom Trl' },
  { key: 'N Pine Hills Rd', value: 'N Pine Hills Rd', text: 'N Pine Hills Rd' },
  { key: 'N Ronald Reagan Blvd', value: 'N Ronald Reagan Blvd', text: 'N Ronald Reagan Blvd' },
  { key: 'N US-1', value: 'N US-1', text: 'N US-1' },
  { key: 'N US-27', value: 'N US-27', text: 'N US-27' },
  { key: 'N Wickham Rd', value: 'N Wickham Rd', text: 'N Wickham Rd' },
  { key: 'NE 100th St', value: 'NE 100th St', text: 'NE 100th St' },
  { key: 'NE 70th St', value: 'NE 70th St', text: 'NE 70th St' },
  { key: 'NE Waldo Rd', value: 'NE Waldo Rd', text: 'NE Waldo Rd' },
  { key: 'NORTH JONES LOOP RD', value: 'NORTH JONES LOOP RD', text: 'NORTH JONES LOOP RD' },
  { key: 'NW 39th Ave', value: 'NW 39th Ave', text: 'NW 39th Ave' },
  { key: 'Narcoossee Rd', value: 'Narcoossee Rd', text: 'Narcoossee Rd' },
  { key: 'Narcoossee Road', value: 'Narcoossee Road', text: 'Narcoossee Road' },
  { key: 'Neptune Rd', value: 'Neptune Rd', text: 'Neptune Rd' },
  { key: 'New Independence Pkwy', value: 'New Independence Pkwy', text: 'New Independence Pkwy' },
  { key: 'New York Ave', value: 'New York Ave', text: 'New York Ave' },
  { key: 'Newberry Rd', value: 'Newberry Rd', text: 'Newberry Rd' },
  { key: 'Nine Mile Rd', value: 'Nine Mile Rd', text: 'Nine Mile Rd' },
  { key: 'Northlake Blvd', value: 'Northlake Blvd', text: 'Northlake Blvd' },
  { key: 'OTHER', value: 'OTHER', text: 'OTHER' },
  { key: 'Oak Rd', value: 'Oak Rd', text: 'Oak Rd' },
  { key: 'Oak Ridge Rd', value: 'Oak Ridge Rd', text: 'Oak Ridge Rd' },
  { key: 'Oakland Park Boulevard', value: 'Oakland Park Boulevard', text: 'Oakland Park Boulevard' },
  { key: 'Ocean Shore Blvd', value: 'Ocean Shore Blvd', text: 'Ocean Shore Blvd' },
  { key: 'Ocean St', value: 'Ocean St', text: 'Ocean St' },
  { key: 'Okeechobee Boulevard', value: 'Okeechobee Boulevard', text: 'Okeechobee Boulevard' },
  { key: 'Old Winter Garden Rd', value: 'Old Winter Garden Rd', text: 'Old Winter Garden Rd' },
  { key: 'Orange Ave', value: 'Orange Ave', text: 'Orange Ave' },
  { key: 'Orange Blossom Trl', value: 'Orange Blossom Trl', text: 'Orange Blossom Trl' },
  { key: 'Orlando Ave', value: 'Orlando Ave', text: 'Orlando Ave' },
  { key: 'Osceola Parkway', value: 'Osceola Parkway', text: 'Osceola Parkway' },
  { key: 'Osceola Polk Line Rd', value: 'Osceola Polk Line Rd', text: 'Osceola Polk Line Rd' },
  { key: 'PINE RIDGE ROAD', value: 'PINE RIDGE ROAD', text: 'PINE RIDGE ROAD' },
  { key: 'Palm Bay Rd', value: 'Palm Bay Rd', text: 'Palm Bay Rd' },
  { key: 'Palm Beach Blvd', value: 'Palm Beach Blvd', text: 'Palm Beach Blvd' },
  { key: 'Palm Beach Lakes Blvd', value: 'Palm Beach Lakes Blvd', text: 'Palm Beach Lakes Blvd' },
  { key: 'Palm Coast Pkwy', value: 'Palm Coast Pkwy', text: 'Palm Coast Pkwy' },
  { key: 'Palm Pkwy', value: 'Palm Pkwy', text: 'Palm Pkwy' },
  { key: 'Pembroke Road', value: 'Pembroke Road', text: 'Pembroke Road' },
  { key: 'Pershing Ave', value: 'Pershing Ave', text: 'Pershing Ave' },
  { key: 'Philips Hwy', value: 'Philips Hwy', text: 'Philips Hwy' },
  { key: 'Pine Ave', value: 'Pine Ave', text: 'Pine Ave' },
  { key: 'Pine Island Rd', value: 'Pine Island Rd', text: 'Pine Island Rd' },
  { key: 'Pines Blvd', value: 'Pines Blvd', text: 'Pines Blvd' },
  { key: 'Pleasant Hill Rd', value: 'Pleasant Hill Rd', text: 'Pleasant Hill Rd' },
  { key: 'Poinciana Blvd', value: 'Poinciana Blvd', text: 'Poinciana Blvd' },
  { key: 'Polk Parkway', value: 'Polk Parkway', text: 'Polk Parkway' },
  { key: 'Port Miami Tunnel', value: 'Port Miami Tunnel', text: 'Port Miami Tunnel' },
  { key: 'Post Rd', value: 'Post Rd', text: 'Post Rd' },
  { key: 'Princeton St', value: 'Princeton St', text: 'Princeton St' },
  { key: 'Quadrille Blvd', value: 'Quadrille Blvd', text: 'Quadrille Blvd' },
  { key: 'RIVER RD', value: 'RIVER RD', text: 'RIVER RD' },
  { key: 'Red Bug Lake Rd', value: 'Red Bug Lake Rd', text: 'Red Bug Lake Rd' },
  { key: 'RedBug Lk Rd', value: 'RedBug Lk Rd', text: 'RedBug Lk Rd' },
  { key: 'Reversible Lane - I595', value: 'Reversible Lane - I595', text: 'Reversible Lane - I595' },
  { key: 'Rinehart Rd', value: 'Rinehart Rd', text: 'Rinehart Rd' },
  { key: 'Rio Grande Ave', value: 'Rio Grande Ave', text: 'Rio Grande Ave' },
  { key: 'Robinson St', value: 'Robinson St', text: 'Robinson St' },
  { key: 'Ronald Reagan Blvd', value: 'Ronald Reagan Blvd', text: 'Ronald Reagan Blvd' },
  { key: 'Rosalind Ave', value: 'Rosalind Ave', text: 'Rosalind Ave' },
  { key: 'Rouse Rd', value: 'Rouse Rd', text: 'Rouse Rd' },
  { key: 'S Apopka Vineland Rd', value: 'S Apopka Vineland Rd', text: 'S Apopka Vineland Rd' },
  { key: 'S Bumby Ave', value: 'S Bumby Ave', text: 'S Bumby Ave' },
  { key: 'S Garland Ave', value: 'S Garland Ave', text: 'S Garland Ave' },
  { key: 'S Orange Ave', value: 'S Orange Ave', text: 'S Orange Ave' },
  { key: 'S River RD', value: 'S River RD', text: 'S River RD' },
  { key: 'S US-17-92', value: 'S US-17-92', text: 'S US-17-92' },
  { key: 'S US-27', value: 'S US-27', text: 'S US-27' },
  { key: 'SE 58th Ave', value: 'SE 58th Ave', text: 'SE 58th Ave' },
  { key: 'SR-10', value: 'SR-10', text: 'SR-10' },
  { key: 'SR-100', value: 'SR-100', text: 'SR-100' },
  { key: 'SR-109', value: 'SR-109', text: 'SR-109' },
  { key: 'SR-112', value: 'SR-112', text: 'SR-112' },
  { key: 'SR-115', value: 'SR-115', text: 'SR-115' },
  { key: 'SR-12', value: 'SR-12', text: 'SR-12' },
  { key: 'SR-121', value: 'SR-121', text: 'SR-121' },
  { key: 'SR-13', value: 'SR-13', text: 'SR-13' },
  { key: 'SR-15', value: 'SR-15', text: 'SR-15' },
  { key: 'SR-152', value: 'SR-152', text: 'SR-152' },
  { key: 'SR-16', value: 'SR-16', text: 'SR-16' },
  { key: 'SR-17', value: 'SR-17', text: 'SR-17' },
  { key: 'SR-19', value: 'SR-19', text: 'SR-19' },
  { key: 'SR-20', value: 'SR-20', text: 'SR-20' },
  { key: 'SR-200', value: 'SR-200', text: 'SR-200' },
  { key: 'SR-202', value: 'SR-202', text: 'SR-202' },
  { key: 'SR-21', value: 'SR-21', text: 'SR-21' },
  { key: 'SR-22', value: 'SR-22', text: 'SR-22' },
  { key: 'SR-222', value: 'SR-222', text: 'SR-222' },
  { key: 'SR-23', value: 'SR-23', text: 'SR-23' },
  { key: 'SR-24', value: 'SR-24', text: 'SR-24' },
  { key: 'SR-26', value: 'SR-26', text: 'SR-26' },
  { key: 'SR-263', value: 'SR-263', text: 'SR-263' },
  { key: 'SR-273', value: 'SR-273', text: 'SR-273' },
  { key: 'SR-276', value: 'SR-276', text: 'SR-276' },
  { key: 'SR-281 (Avalon Blvd)', value: 'SR-281 (Avalon Blvd)', text: 'SR-281 (Avalon Blvd)' },
  { key: 'SR-285', value: 'SR-285', text: 'SR-285' },
  { key: 'SR-29', value: 'SR-29', text: 'SR-29' },
  { key: 'SR-291', value: 'SR-291', text: 'SR-291' },
  { key: 'SR-293', value: 'SR-293', text: 'SR-293' },
  { key: 'SR-295', value: 'SR-295', text: 'SR-295' },
  { key: 'SR-296', value: 'SR-296', text: 'SR-296' },
  { key: 'SR-297 (Pine Forrest Rd)', value: 'SR-297 (Pine Forrest Rd)', text: 'SR-297 (Pine Forrest Rd)' },
  { key: 'SR-3', value: 'SR-3', text: 'SR-3' },
  { key: 'SR-31', value: 'SR-31', text: 'SR-31' },
  { key: 'SR-326', value: 'SR-326', text: 'SR-326' },
  { key: 'SR-33', value: 'SR-33', text: 'SR-33' },
  { key: 'SR-331', value: 'SR-331', text: 'SR-331' },
  { key: 'SR-35', value: 'SR-35', text: 'SR-35' },
  { key: 'SR-37', value: 'SR-37', text: 'SR-37' },
  { key: 'SR-4', value: 'SR-4', text: 'SR-4' },
  { key: 'SR-40', value: 'SR-40', text: 'SR-40' },
  { key: 'SR-400', value: 'SR-400', text: 'SR-400' },
  { key: 'SR-404', value: 'SR-404', text: 'SR-404' },
  { key: 'SR-405', value: 'SR-405', text: 'SR-405' },
  { key: 'SR-406', value: 'SR-406', text: 'SR-406' },
  { key: 'SR-408', value: 'SR-408', text: 'SR-408' },
  { key: 'SR-414', value: 'SR-414', text: 'SR-414' },
  { key: 'SR-415', value: 'SR-415', text: 'SR-415' },
  { key: 'SR-417', value: 'SR-417', text: 'SR-417' },
  { key: 'SR-419', value: 'SR-419', text: 'SR-419' },
  { key: 'SR-421', value: 'SR-421', text: 'SR-421' },
  { key: 'SR-423', value: 'SR-423', text: 'SR-423' },
  { key: 'SR-424', value: 'SR-424', text: 'SR-424' },
  { key: 'SR-426', value: 'SR-426', text: 'SR-426' },
  { key: 'SR-429', value: 'SR-429', text: 'SR-429' },
  { key: 'SR-434', value: 'SR-434', text: 'SR-434' },
  { key: 'SR-435', value: 'SR-435', text: 'SR-435' },
  { key: 'SR-436', value: 'SR-436', text: 'SR-436' },
  { key: 'SR-438', value: 'SR-438', text: 'SR-438' },
  { key: 'SR-44', value: 'SR-44', text: 'SR-44' },
  { key: 'SR-451', value: 'SR-451', text: 'SR-451' },
  { key: 'SR-453', value: 'SR-453', text: 'SR-453' },
  { key: 'SR-46', value: 'SR-46', text: 'SR-46' },
  { key: 'SR-471', value: 'SR-471', text: 'SR-471' },
  { key: 'SR-472', value: 'SR-472', text: 'SR-472' },
  { key: 'SR-482', value: 'SR-482', text: 'SR-482' },
  { key: 'SR-50', value: 'SR-50', text: 'SR-50' },
  { key: 'SR-501', value: 'SR-501', text: 'SR-501' },
  { key: 'SR-507', value: 'SR-507', text: 'SR-507' },
  { key: 'SR-514', value: 'SR-514', text: 'SR-514' },
  { key: 'SR-518', value: 'SR-518', text: 'SR-518' },
  { key: 'SR-519', value: 'SR-519', text: 'SR-519' },
  { key: 'SR-52', value: 'SR-52', text: 'SR-52' },
  { key: 'SR-520', value: 'SR-520', text: 'SR-520' },
  { key: 'SR-524', value: 'SR-524', text: 'SR-524' },
  { key: 'SR-526', value: 'SR-526', text: 'SR-526' },
  { key: 'SR-527', value: 'SR-527', text: 'SR-527' },
  { key: 'SR-528', value: 'SR-528', text: 'SR-528' },
  { key: 'SR-535', value: 'SR-535', text: 'SR-535' },
  { key: 'SR-536', value: 'SR-536', text: 'SR-536' },
  { key: 'SR-539', value: 'SR-539', text: 'SR-539' },
  { key: 'SR-54', value: 'SR-54', text: 'SR-54' },
  { key: 'SR-551', value: 'SR-551', text: 'SR-551' },
  { key: 'SR-552', value: 'SR-552', text: 'SR-552' },
  { key: 'SR-56', value: 'SR-56', text: 'SR-56' },
  { key: 'SR-563', value: 'SR-563', text: 'SR-563' },
  { key: 'SR-568', value: 'SR-568', text: 'SR-568' },
  { key: 'SR-572', value: 'SR-572', text: 'SR-572' },
  { key: 'SR-574', value: 'SR-574', text: 'SR-574' },
  { key: 'SR-580', value: 'SR-580', text: 'SR-580' },
  { key: 'SR-589', value: 'SR-589', text: 'SR-589' },
  { key: 'SR-60', value: 'SR-60', text: 'SR-60' },
  { key: 'SR-600', value: 'SR-600', text: 'SR-600' },
  { key: 'SR-61', value: 'SR-61', text: 'SR-61' },
  { key: 'SR-62', value: 'SR-62', text: 'SR-62' },
  { key: 'SR-64', value: 'SR-64', text: 'SR-64' },
  { key: 'SR-684', value: 'SR-684', text: 'SR-684' },
  { key: 'SR-69', value: 'SR-69', text: 'SR-69' },
  { key: 'SR-694', value: 'SR-694', text: 'SR-694' },
  { key: 'SR-70', value: 'SR-70', text: 'SR-70' },
  { key: 'SR-71', value: 'SR-71', text: 'SR-71' },
  { key: 'SR-72', value: 'SR-72', text: 'SR-72' },
  { key: 'SR-73', value: 'SR-73', text: 'SR-73' },
  { key: 'SR-739', value: 'SR-739', text: 'SR-739' },
  { key: 'SR-758', value: 'SR-758', text: 'SR-758' },
  { key: 'SR-77', value: 'SR-77', text: 'SR-77' },
  { key: 'SR-776', value: 'SR-776', text: 'SR-776' },
  { key: 'SR-777', value: 'SR-777', text: 'SR-777' },
  { key: 'SR-78', value: 'SR-78', text: 'SR-78' },
  { key: 'SR-780', value: 'SR-780', text: 'SR-780' },
  { key: 'SR-79', value: 'SR-79', text: 'SR-79' },
  { key: 'SR-80', value: 'SR-80', text: 'SR-80' },
  { key: 'SR-81', value: 'SR-81', text: 'SR-81' },
  { key: 'SR-82', value: 'SR-82', text: 'SR-82' },
  { key: 'SR-826', value: 'SR-826', text: 'SR-826' },
  { key: 'SR-836', value: 'SR-836', text: 'SR-836' },
  { key: 'SR-84', value: 'SR-84', text: 'SR-84' },
  { key: 'SR-842', value: 'SR-842', text: 'SR-842' },
  { key: 'SR-85', value: 'SR-85', text: 'SR-85' },
  { key: 'SR-860', value: 'SR-860', text: 'SR-860' },
  { key: 'SR-865', value: 'SR-865', text: 'SR-865' },
  { key: 'SR-867', value: 'SR-867', text: 'SR-867' },
  { key: 'SR-87', value: 'SR-87', text: 'SR-87' },
  { key: 'SR-874', value: 'SR-874', text: 'SR-874' },
  { key: 'SR-878', value: 'SR-878', text: 'SR-878' },
  { key: 'SR-884', value: 'SR-884', text: 'SR-884' },
  { key: 'SR-9', value: 'SR-9', text: 'SR-9' },
  { key: 'SR-924', value: 'SR-924', text: 'SR-924' },
  { key: 'SR-997', value: 'SR-997', text: 'SR-997' },
  { key: 'SR-9B', value: 'SR-9B', text: 'SR-9B' },
  { key: 'SR-A1A', value: 'SR-A1A', text: 'SR-A1A' },
  { key: 'SUMTER BLVD', value: 'SUMTER BLVD', text: 'SUMTER BLVD' },
  { key: 'SW 10th St', value: 'SW 10th St', text: 'SW 10th St' },
  { key: 'SW 13th St', value: 'SW 13th St', text: 'SW 13th St' },
  { key: 'SW CR-484', value: 'SW CR-484', text: 'SW CR-484' },
  { key: 'SW Highway 200', value: 'SW Highway 200', text: 'SW Highway 200' },
  { key: 'San Carlos Blvd', value: 'San Carlos Blvd', text: 'San Carlos Blvd' },
  { key: 'San Jose Blvd', value: 'San Jose Blvd', text: 'San Jose Blvd' },
  { key: 'Sand Lake Rd', value: 'Sand Lake Rd', text: 'Sand Lake Rd' },
  { key: 'Sanibel Cswy', value: 'Sanibel Cswy', text: 'Sanibel Cswy' },
  { key: 'Sarno Rd', value: 'Sarno Rd', text: 'Sarno Rd' },
  { key: 'Sawgrass Expressway', value: 'Sawgrass Expressway', text: 'Sawgrass Expressway' },
  { key: 'Saxon Blvd', value: 'Saxon Blvd', text: 'Saxon Blvd' },
  { key: 'Semoran Blvd', value: 'Semoran Blvd', text: 'Semoran Blvd' },
  { key: 'Silver Springs Blvd', value: 'Silver Springs Blvd', text: 'Silver Springs Blvd' },
  { key: 'Silver Star Rd', value: 'Silver Star Rd', text: 'Silver Star Rd' },
  { key: 'Simpson Rd', value: 'Simpson Rd', text: 'Simpson Rd' },
  { key: 'Southern Boulevard', value: 'Southern Boulevard', text: 'Southern Boulevard' },
  { key: 'Southside Blvd', value: 'Southside Blvd', text: 'Southside Blvd' },
  { key: 'Sr-535', value: 'Sr-535', text: 'Sr-535' },
  { key: 'St Augustine', value: 'St Augustine', text: 'St Augustine' },
  { key: 'State Road 681', value: 'State Road 681', text: 'State Road 681' },
  { key: 'State Road 80', value: 'State Road 80', text: 'State Road 80' },
  { key: 'Stickney Point Rd', value: 'Stickney Point Rd', text: 'Stickney Point Rd' },
  { key: 'Sumter', value: 'Sumter', text: 'Sumter' },
  { key: 'Suncoast Parkway', value: 'Suncoast Parkway', text: 'Suncoast Parkway' },
  { key: 'Sunrise Blvd', value: 'Sunrise Blvd', text: 'Sunrise Blvd' },
  { key: 'TOLEDO BLADE BLVD', value: 'TOLEDO BLADE BLVD', text: 'TOLEDO BLADE BLVD' },
  { key: 'TUCKERS GRADE RD', value: 'TUCKERS GRADE RD', text: 'TUCKERS GRADE RD' },
  { key: 'Taft Vineland Rd', value: 'Taft Vineland Rd', text: 'Taft Vineland Rd' },
  { key: 'Terminal Access Rd', value: 'Terminal Access Rd', text: 'Terminal Access Rd' },
  { key: 'Tomoka Farms Rd', value: 'Tomoka Farms Rd', text: 'Tomoka Farms Rd' },
  { key: 'Tradeport Dr', value: 'Tradeport Dr', text: 'Tradeport Dr' },
  { key: 'Truman Ave', value: 'Truman Ave', text: 'Truman Ave' },
  { key: 'Turkey Lake Rd', value: 'Turkey Lake Rd', text: 'Turkey Lake Rd' },
  { key: 'Tuskawilla Rd', value: 'Tuskawilla Rd', text: 'Tuskawilla Rd' },
  { key: 'UNIVERSITY PARKWAY', value: 'UNIVERSITY PARKWAY', text: 'UNIVERSITY PARKWAY' },
  { key: 'UNIVERSITY PKWY', value: 'UNIVERSITY PKWY', text: 'UNIVERSITY PKWY' },
  { key: 'US 19', value: 'US 19', text: 'US 19' },
  { key: 'US-1', value: 'US-1', text: 'US-1' },
  { key: 'US-1 ALT', value: 'US-1 ALT', text: 'US-1 ALT' },
  { key: 'US-129', value: 'US-129', text: 'US-129' },
  { key: 'US-17', value: 'US-17', text: 'US-17' },
  { key: 'US-17-92', value: 'US-17-92', text: 'US-17-92' },
  { key: 'US-19', value: 'US-19', text: 'US-19' },
  { key: 'US-19 (SR-57)', value: 'US-19 (SR-57)', text: 'US-19 (SR-57)' },
  { key: 'US-192', value: 'US-192', text: 'US-192' },
  { key: 'US-231', value: 'US-231', text: 'US-231' },
  { key: 'US-27', value: 'US-27', text: 'US-27' },
  { key: 'US-29', value: 'US-29', text: 'US-29' },
  { key: 'US-301', value: 'US-301', text: 'US-301' },
  { key: 'US-319', value: 'US-319', text: 'US-319' },
  { key: 'US-331', value: 'US-331', text: 'US-331' },
  { key: 'US-41', value: 'US-41', text: 'US-41' },
  { key: 'US-41 Business', value: 'US-41 Business', text: 'US-41 Business' },
  { key: 'US-441', value: 'US-441', text: 'US-441' },
  { key: 'US-90', value: 'US-90', text: 'US-90' },
  { key: 'US-90 ALT', value: 'US-90 ALT', text: 'US-90 ALT' },
  { key: 'US-92', value: 'US-92', text: 'US-92' },
  { key: 'US-98', value: 'US-98', text: 'US-98' },
  { key: 'US301', value: 'US301', text: 'US301' },
  { key: 'Universal Blvd', value: 'Universal Blvd', text: 'Universal Blvd' },
  { key: 'University Ave', value: 'University Ave', text: 'University Ave' },
  { key: 'University Blvd', value: 'University Blvd', text: 'University Blvd' },
  { key: 'University Drive', value: 'University Drive', text: 'University Drive' },
  { key: 'Veterans Expressway', value: 'Veterans Expressway', text: 'Veterans Expressway' },
  { key: 'Veterans Expressway Express Lanes', value: 'Veterans Expressway Express Lanes', text: 'Veterans Expressway Express Lanes' },
  { key: 'Vine St', value: 'Vine St', text: 'Vine St' },
  { key: 'Vineland Rd', value: 'Vineland Rd', text: 'Vineland Rd' },
  { key: 'W 41st St', value: 'W 41st St', text: 'W 41st St' },
  { key: 'W Airport Blvd', value: 'W Airport Blvd', text: 'W Airport Blvd' },
  { key: 'W Amelia St', value: 'W Amelia St', text: 'W Amelia St' },
  { key: 'W Chapman Rd', value: 'W Chapman Rd', text: 'W Chapman Rd' },
  { key: 'W Gore St', value: 'W Gore St', text: 'W Gore St' },
  { key: 'W International Speedway Blvd', value: 'W International Speedway Blvd', text: 'W International Speedway Blvd' },
  { key: 'W Kaley Ave', value: 'W Kaley Ave', text: 'W Kaley Ave' },
  { key: 'W Lancaster Rd', value: 'W Lancaster Rd', text: 'W Lancaster Rd' },
  { key: 'W Oak Ridge Rd', value: 'W Oak Ridge Rd', text: 'W Oak Ridge Rd' },
  { key: 'W Sr-46', value: 'W Sr-46', text: 'W Sr-46' },
  { key: 'Wekiwa Springs Rd', value: 'Wekiwa Springs Rd', text: 'Wekiwa Springs Rd' },
  { key: 'West Rd', value: 'West Rd', text: 'West Rd' },
  { key: 'West Town Pkwy', value: 'West Town Pkwy', text: 'West Town Pkwy' },
  { key: 'Wickham Rd', value: 'Wickham Rd', text: 'Wickham Rd' },
  { key: 'Williston Rd', value: 'Williston Rd', text: 'Williston Rd' },
  { key: 'Winter Garden Vineland Rd', value: 'Winter Garden Vineland Rd', text: 'Winter Garden Vineland Rd' },
  { key: 'World Center Dr', value: 'World Center Dr', text: 'World Center Dr' },
  { key: 'Wrights Rd', value: 'Wrights Rd', text: 'Wrights Rd' },
]

export const direction = [
  { key: null, value: null, text: 'Select...' },
  { key: 'N', value: 'N', text: 'N' },
  { key: 'S', value: 'S', text: 'S' },
  { key: 'E', value: 'E', text: 'E' },
  { key: 'W', value: 'W', text: 'W' },
]
