import React, { useEffect, useState, useMemo } from 'react';
import './pagination.css';
import './options.css'
import { region, county, roadway, direction } from './data'
import TableMobile from './tableMobile';
import Items from './tableDesktop';
import Loading from './loading';
import { trackPromise } from 'react-promise-tracker';

import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-date-picker';
import debounce from 'lodash.debounce';

function Pagination() {
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [recordsLength, setRecordsLength] = useState(0);

  const [newSearch, setNewSearch] = useState(false);
  const [regionSearch, setRegionSearch] = useState(null);
  const [countySearch, setCountySearch] = useState(null);
  const [roadwaySearch, setRoadwaySearch] = useState(null);
  const [dateSearch, setDateSearch] = useState(null);
  const [idSearch, setIdSearch] = useState("");
  const [directionSearch, setDirectionSearch] = useState(null);

  const [currPage, setCurrPage] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <FontAwesomeIcon icon={faCaretDown} size='lg'/ > : <FontAwesomeIcon icon={faCaretRight} size='lg'/>}
          </span>
        ),
      },
      {
        Header: 'Table',
        columns: [
          {
            Header: 'County',
            accessor: 'county',
          },
          {
            Header: 'Region',
            accessor: 'region',
          },
          {
            Header: 'Roadway Name',
            accessor: 'roadway',
          },
          {
            Header: 'Date',
            accessor: 'start_time',
          },
        ],
      },
    ],
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function changeHandler() {
    setNewSearch(!newSearch);
    setCurrPage(0);
    setItemOffset(0);
  };

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(() => {
        changeHandler();
      }, 1000),
    [changeHandler],
  );

  useEffect(() => {    
    var params = new URLSearchParams();

    params.append('offset', itemOffset);
    params.append('limit', itemsPerPage);
    if(regionSearch) params.append('region', regionSearch);
    if(countySearch) params.append('county', countySearch);
    if(roadwaySearch) params.append('roadway', roadwaySearch);
    if(idSearch) params.append('id', idSearch);
    if(directionSearch) params.append('direction', directionSearch);
    if(dateSearch) {
      var date = new Date(dateSearch);

      var month = date.getMonth() + 1;
      var day = date.getDate();
      var year = date.getFullYear();
      if(month < 10) {
        month = '0' + month;
      }
      if(day < 10) {
        day = '0' + day;
      }

      var dateString = year + '-' + month + '-' + day;
      params.append('date', dateString);
    }
    trackPromise(
      axios.post('https://pktraffic.com/api/records.php', params).then(response => {
        if (response.data.count !== recordsLength) {
          setPageCount(Math.ceil(response.data.count / itemsPerPage));
          setRecordsLength(response.data.count);
        }
        setCurrentItems(response.data.crashes);
      }).catch(response => {
        console.log(response);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, newSearch]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % recordsLength;
    setCurrPage(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <div className="pagination-list">
      <div className="records-option">
        <div className="grid-item">
          <label>Region</label>
          <Dropdown
            placeholder='Select Region'
            fluid
            search
            selection
            options={region}
            value={regionSearch}
            onChange={(value, text, $selectedItem) => {
              setRegionSearch(text.value);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            }}
            className="option-region"
          />
        </div>
        <div className="grid-item">
          <label>County</label>
          <Dropdown
            placeholder='Select County'
            fluid
            search
            selection
            options={county}
            value={countySearch}
            onChange={(value, text, $selectedItem) => {
              setCountySearch(text.value);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            }}
            className="option-county"
          />
        </div>
        <div className="grid-item">
          <label>Roadway</label>
          <Dropdown
            placeholder='Select Roadway'
            fluid
            search
            selection
            options={roadway}
            value={roadwaySearch}
            onChange={(value, text, $selectedItem) => {
              setRoadwaySearch(text.value);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            }}
            className="option-roadway"
          />
        </div>
        <div className="grid-item">
          <label>Direction</label>
          <Dropdown
            placeholder='Select Direction'
            fluid
            search
            selection
            options={direction}
            value={directionSearch}
            onChange={(value, text, $selectedItem) => {
              setDirectionSearch(text.value);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            }}
            className="option-direction"
          />
        </div>
        <div className="grid-item">
          <label>Crash ID</label>
          <Input
            placeholder='Search ID...'
            fluid
            value={idSearch}
            onChange={(event, { value }) => {
              setIdSearch(value);
              debouncedChangeHandler();
            }}
            className="option-id"
            icon="search"
          />
        </div>
        <div className="grid-item">
          <label>Date</label>
          <DatePicker
            onChange={(date => {
              setDateSearch(date);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            })}
            value={dateSearch}
            className={'option-date'}
            clearIcon={null}
            calendarIcon={<Icon name='calendar alternate outline' size='large' />}
          />
        </div>
        <div className="option-reset">
          <Button
            icon
            labelPosition='right'
            onClick={() => {
              setIdSearch("");
              setDateSearch(null);
              setRegionSearch(null);
              setCountySearch(null);
              setRoadwaySearch(null);
              setDirectionSearch(null);
              setCurrPage(0);
              setItemOffset(0);
              setNewSearch(!newSearch);
            }}
          >
            Reset Filter
            <Icon name='redo' />
          </Button>
        </div>
      </div>
      <div className="pagination-container">
      <Loading />
        <Items currentItems={currentItems} />
        {currentItems ? (
        <TableMobile columns={columns} data={currentItems} />) :
          <p>No Data</p>
        }
        <div className="pagination-controls">
        
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className='pagination'
            forcePage={currPage}
          />
          <div className="mobile-page-count">
            <p>{itemOffset / itemsPerPage + 1}  /  {pageCount}</p>
          </div>
          <div className="entires-text">
            <p>Showing {currentItems ? currentItems.length : 0} of {recordsLength} entries</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
