import React from 'react';
import { useNavigate } from "react-router-dom";
import './navigation.css';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { HashLink } from 'react-router-hash-link';
import Logo from './Logo.png';

const includedUrls = ['/', '/records'];


function scrollFunction() {
	if (document.documentElement.scrollTop > 60) {
		document.getElementById("navigation-bar").style.height = "50px";
		document.getElementById("navbar-shadow").classList.add("navbar-box_shadow");
	} else if ( document.documentElement.scrollTop < 60 ) {
		document.getElementById("navigation-bar").style.height = "100px";
    document.getElementById("navbar-shadow").classList.remove("navbar-box_shadow");
	}
}

window.onscroll = function () {
  if (includedUrls.includes(window.location.pathname)) {
	  scrollFunction();
  }
};

window.onload = function () {
  if (includedUrls.includes(window.location.pathname)) {
    scrollFunction();
  }
};

function Navigation(props) {
	const [expanded, setExpanded] = React.useState(false);
	let navigate = useNavigate();

  return (
    <Navbar collapseOnSelect className="navbar" id='navbar-shadow' fixed="top" expand="lg" expanded={expanded}>
      <Container>
        <Nav.Link  onClick={() => {navigate("/"); setExpanded(false)}}>
          <Navbar.Brand className="navbar-brand" id="navigation-bar">
            <img src={Logo} alt="logo" className="navbar-logo" />
          </Navbar.Brand>
        </Nav.Link>
        
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="nav">
            <HashLink className="nav-item navigation-black" to="/#home" onClick={() => setExpanded(false)}>Home</HashLink>
            <HashLink className="nav-item center-margin" to="/#details" onClick={() => setExpanded(false)}>Services</HashLink>
            <HashLink className="nav-item navigation-black" to="/#aboutus" onClick={() => setExpanded(false)}>About Us</HashLink>
            <Nav.Link className="nav-item records-navigation" to="/records" onClick={() => {navigate("/records"); setExpanded(false)}}>Search Records</Nav.Link>
            <HashLink to="/#contact" onClick={() => setExpanded(false)}><Button compact className='nav-button nav-item'>Contact Us</Button></HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
