import {
    getAuth,
    onAuthStateChanged,
    setPersistence,
    browserLocalPersistence,
    signInWithEmailAndPassword,
    updateProfile
} from "firebase/auth";

import profileImage from "./profile-holder.png";


class Model {
    constructor() {
        this.user = null;
        this.recordsLength = 0;
        this.observers = [];

        this.auth = getAuth();
        // this.setPersistence();
    }

    addObserver(observer) {
        this.observers.push(observer);
    }

    removeObserver(observer) {
        this.observers = this.observers.filter(obs => obs !== observer);
    }

    notifyObservers() {
        this.observers.forEach(observer => observer());
    }

    async signIn(email, password) {        
        await signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
            this.setUser(userCredential.user);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    async signOut() {
        await this.auth.signOut();
        this.setUser(null);

        this.notifyObservers();
    }

    setUser(user) {
        this.user = user;
        this.notifyObservers();
    }

    getUser() {
        return this.user;
    }

    isLoggedIn() {
        return this.user !== null;
    }

    getUserImage() {
        if (this.user && this.user.photoURL !== null) {
            return this.user.photoURL;
        } else { 
            return profileImage;
        }
    }

    getUserName() {
        if (this.user && this.user.displayName !== null) {
            return this.user.displayName;
        } else {
            return "No name";
        }
    }

    setUserName(firstName, lastName) {
        updateProfile(this.auth.currentUser, {
            displayName: `${firstName} ${lastName}`
        }).then(() => {
            this.notifyObservers();
        }).catch((error) => {
            console.log(error);
        });
    }

    setUserImage(imagePath) {
        updateProfile(this.auth.currentUser, {
            photoURL: imagePath
        }).then(() => {
            this.notifyObservers();
        }).catch((error) => {
            console.log(error);
        });
    }

    async setPersistence() {
        setPersistence(this.auth, browserLocalPersistence).then(() => {
            onAuthStateChanged(this.auth, (user) => {
                if (user) {
                    this.user = user;
                    this.notifyObservers();
                }
            });
        });
    }
}

export default Model;
