import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const OverViewGraph = (props) => {
  const [crashesWeekCompare, setCrashesWeekCompare] = useState(0);
  const [showPoints, setShowPoints] = useState(true);

  useEffect(() => {
    setCrashesWeekCompare(compareData(props.crashesSystem));
    if (props.period > 30) {
      setShowPoints(false);
    } else {
      setShowPoints(true);
    }
  }, [props.crashesSystem, props.period]);

  const percentageIcon = crashesWeekCompare < 0 ? faAngleDown : faAngleUp;
  const percentageColor = crashesWeekCompare < 0 ? "text-danger" : "text-success";

  return (
    <Card className="bg-secondary-alt shadow-sm" style={{width: "100%"}}>
      <Card.Header className="d-flex flex-row align-items-center flex-0" style={{textAlign: "left"}}>
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            {props.period === 999 ? "Crashes Total" : `Crashes Last ${props.period} Days`}
          </h5>
          <h4>{totalCrashes(props.crashesSystemPreview)} Crashes</h4>
          <small className="fw-bold mt-2">
            <span className="me-2">Last 7 Days</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {crashesWeekCompare.toFixed(2)}%
            </span>
          </small>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart data={props.crashesSystemPreview} showPoints={showPoints}/>
      </Card.Body>
    </Card>
  );
};

const SalesValueChart = (props) => {

  const data = {
    labels: props.data.map(item => item.date).reverse(),
    series: [props.data.map(item => item.count).reverse()]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: props.showPoints,
    axisX: {
      position: 'left',
      showGrid: true,
      labelInterpolationFnc: function (value, index) {
        return index % (props.data.length / 10) < 1 ? dateFixer(value) : null;
      }
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: true,
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-series-g ct-double-octave" />
  );
};

function compareData(data) {
  let period7 = data.slice(0, 7);
  let period14 = data.slice(7, 14);

  let period7Count = 0;
  let period14Count = 0;

  for (let i = 0; i < period7.length; i++) {
    period7Count += parseInt(period7[i].count);
  }

  for (let i = 0; i < period14.length; i++) {
    period14Count += parseInt(period14[i].count);
  }

  return ((period7Count - period14Count) / period14Count) * 100;
}

function totalCrashes(data) {
  let totalCrashes = 0;
  for (let i = 0; i < data.length; i++) {
    totalCrashes += parseInt(data[i].count);
  }
  return totalCrashes;
}

function dateFixer(date) {
  return date.split("-").slice(1, 3).join("/");
}
