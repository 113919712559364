import React from 'react';
import { Card } from '@themesberg/react-bootstrap';

export const Divider = (props) => {
  return (
    <Card className="shadow-sm" style={{width: "100%", backgroundColor: "#ACEBFD"}}>
      <Card.Header className="" style={{textAlign: "center"}}>
        <div className="d-block">
          <h2 className="" style={{color: "black", marginBottom: "auto !important", fontWeight: "500"}}>
            {props.title}
          </h2>
        </div>
      </Card.Header>
    </Card>
  );
};
