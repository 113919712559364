
export const Routes = {
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    SystemRecords: { path: "/dashboard/system-records" },
    FL511Records: { path: "/dashboard/fl511-records" },
    ErrorRecords: { path: "/dashboard/error-records" },
    ServerStatusRecords: { path: "/dashboard/server-status-records" },
    SSOGIS: { path: "/dashboard/ssogis" },
    Settings: { path: "/dashboard/settings" },
    Videos: { path: "/dashboard/videos" },
    RawData: { path: "/dashboard/data" },
    Other: { path: "/dashboard/other" },
};