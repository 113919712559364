import React, { useMemo } from "react";
import { region, county, roadway, direction } from './data'

import './pagination.css';
import './options.css'

import { Dropdown, Input, Button, Icon,  } from 'semantic-ui-react'
import DatePicker from 'react-date-picker';
import debounce from 'lodash.debounce';

export default (props) => {
  const debouncedChangeHandler = useMemo(
    () =>
      debounce(() => {
        props.newSearch();
      }, 1000),
    [props],
  );

	return(
		<div className="records-option dashboard-option">
			<div className="grid-item">
				<label>Region</label>
				<Dropdown
					placeholder='Select Region'
					fluid
					search
					selection
					options={region}
					value={props.option.region}
					onChange={(value, text, $selectedItem) => {
						props.setOption({...props.option, region: text.value});
						props.newSearch();
					}}
					className="option-region"
				/>
			</div>
			<div className="grid-item">
				<label>County</label>
				<Dropdown
					placeholder='Select County'
					fluid
					search
					selection
					options={county}
					value={props.option.county}
					onChange={(value, text, $selectedItem) => {
						props.setOption({...props.option, county: text.value});
						props.newSearch();
					}}
					className="option-county"
				/>
			</div>
			<div className="grid-item">
				<label>Roadway</label>
				<Dropdown
					placeholder='Select Roadway'
					fluid
					search
					selection
					options={roadway}
					value={props.option.roadway}
					onChange={(value, text, $selectedItem) => {
						props.setOption({...props.option, roadway: text.value});
						props.newSearch();
					}}
					className="option-roadway"
				/>
			</div>
			<div className="grid-item">
				<label>Direction</label>
				<Dropdown
					placeholder='Select Direction'
					fluid
					search
					selection
					options={direction}
					value={props.option.direction}
					onChange={(value, text, $selectedItem) => {
						props.setOption({...props.option, direction: text.value});
						props.newSearch();
					}}
					className="option-direction"
				/>
			</div>
			<div className="grid-item">
				<label>Crash ID</label>
				<Input
					placeholder='Search ID...'
					fluid
					value={props.option.id}
					onChange={(event, { value }) => {
						props.setOption({...props.option, id: value});
						debouncedChangeHandler();
					}}
					className="option-id"
					icon="search"
				/>
			</div>
			<div className="grid-item">
				<label>Date</label>
				<DatePicker
					onChange={(date => {
						props.setOption({...props.option, date: date});
						props.newSearch();
					})}
					value={props.option.date}
					className={'option-date'}
					clearIcon={null}
					calendarIcon={<Icon name='calendar alternate outline' size='large' />}
				/>
			</div>
      <div className="grid-item">
        <label>Description</label>
        <Input
          placeholder='Search Description...'
          fluid
          value={props.option.description}
          onChange={(event, { value }) => {
            props.setOption({...props.option, description: value});
            debouncedChangeHandler();
          }}
          className="description"
          icon="search"
        />
      </div>
			<div className="option-reset dashboard-reset">
				<Button
					icon
					labelPosition='right'
					onClick={() => {
						props.setOption({
							region: "",
							county: "",
							roadway: "",
							direction: "",
							date: "",
							id: "",
              description: ""
						});
            props.newSearch();
					}}
				>
					Reset Filter
					<Icon name='redo' />
				</Button>
			</div>
		</div>
	);
}