import React from "react";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const CircleChartWidget = (props) => {
  const series = props.data.map(d => d.value);

  const data = props.data.map((item, index) => {
    return {
      ...item,
      id: index,
      color: getColor(index)
    };
  });
  
  return (
    <Card border="light" className="shadow-sm" style={{width: "100%", paddingBottom: "-5px"}}>
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <CircleChart series={series} />
          </Col>
          <Col xs={12} xl={7} className="px-xl-0 cards-mobile" style={{textAlign: "left"}}>
            <h5 className="mb-3">{props.title}</h5>

            {data.map(d => (
              <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
                <FontAwesomeIcon icon={d.icon} className={`icon icon-ls text-${d.color} w-40 me-1`} />
                {` ${d.label} `}{`${d.value}%`}
              </h6>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function getColor(index) {
  const colors = [
    "tertiary",
    "secondary",
  ];

  return colors[index % colors.length];
}

const CircleChart = (props) => {
  const { series = [], donutWidth = 20 } = props;

  const options = {
    low: 0,
    high: 100,
    donutWidth,
    donut: true,
    donutSolid: false,
    fullWidth: false,
    showLabel: false,
  }

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={{ series }} options={{...options, plugins}} type="Pie" className="ct-golden-section" />
  );
};