import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { OverViewGraph } from "../Widgets/overViewGraph";
import { CounterWidget } from "../Widgets/counterWidget";
import { ErrorCard } from "../Widgets/errorCard.js";
import { ErrorGraph } from "../Widgets/errorGraph.js";
import { CircleChartWidget } from "../Widgets/circleChartWidget";
import { ComparisonGraph } from '../Widgets/comparisonGraph';
import { Divider } from '../Widgets/divider';
import { HalfCircleChart } from '../Widgets/halfCircleChart';
import { CameraCountGraph } from '../Widgets/cameraCountGraph';
import { SystemComparisonGraph } from '../Widgets/systemComparisonGraph';
import axios from 'axios';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import "./graph.css"


export default () => {
  const [period, setPeriod] = useState(30);
  const [crashesSystem, setCrashesSystem] = useState([]);
  const [crashesFL, setCrashesFL] = useState([]);

  const [crashesSystemPreview, setCrashesSystemPreview] = useState([]);
  const [crashesFLPreview, setCrashesFLPreview] = useState([]);

  const [serverStatus, setServerStatus] = useState([]);
  const [errorStats, setErrorStats] = useState([]);

  useEffect(() => {
    var params = new URLSearchParams();
    var dataLength = 0;

    if (period < 60) {
      params.append('period', 59);
      dataLength = 60;
    } else {
      params.append('period', period - 1);
      dataLength = period;
    }

    axios.post('https://pktraffic.com/api/crashes_active.php', params)
    .then(response => {
      setCrashesSystemPreview(validateData(response.data['crashesSystem'], period));
      setCrashesFLPreview(validateData(response.data['crashesFL'], period));

      setCrashesSystem(validateData(response.data['crashesSystem'], dataLength));
      setCrashesFL(validateData(response.data['crashesFL'], dataLength));
    }).catch(response => {
      console.log(response);
    })

    axios.post('https://pktraffic.com/api/server_statistics.php', params)
    .then(response => {
      setServerStatus(response.data['serverStatus'].reverse());
    }).catch(response => {
      console.log(response);
    })

    axios.post('https://pktraffic.com/api/error_statistics.php', params)
    .then(response => {
      setErrorStats(response.data['errorStatistics'].reverse());
    }).catch(response => {
      console.log(response);
    })
  }, [period]);

  const handleExport = (type) => {
    var params = new URLSearchParams();
    params.append('period', period);

    if (type === 'system') params.append('type', 'crashes_record');
    else params.append('type', 'crashes_history');

    axios.post('https://pktraffic.com/api/records_dump.php', params)
    .then(response => {
      const ws = XLSX.utils.json_to_sheet(response.data['data']);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: 'xlsx' });
      FileSaver.saveAs(data, 'crashes.xlsx');
    }).catch(response => {
      console.log(response);
    })
  }


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" active={period === 3 ? true : false} size="sm" onClick={() => setPeriod(3)}>3 Days</Button>
          <Button variant="outline-primary" active={period === 7 ? true : false} size="sm" onClick={() => setPeriod(7)}>Week</Button>
          <Button variant="outline-primary" active={period === 30 ? true : false} size="sm" onClick={() => setPeriod(30)}>Month</Button>
          <Button variant="outline-primary" active={period === 365 ? true : false} size="sm" onClick={() => setPeriod(365)}>Year</Button>
          <Button variant="outline-primary" active={period === 999 ? true : false} size="sm" onClick={() => setPeriod(999)}>Total</Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={() => {handleExport("system")}}>Export System</Button>
          <Button variant="outline-primary" size="sm" onClick={() => {handleExport("FL511")}}>Export FL511</Button>
        </ButtonGroup>
      </div>

      <Row className="justify-content-md-center overviewGraph">
        <Col xs={12} className="mb-4 d-sm-block">
          <OverViewGraph
            crashesSystem={crashesSystem}
            crashesSystemPreview={crashesSystemPreview}
            period={period}
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Crashes System"
            crashes={crashesSystem}
            crashesPreview={crashesSystemPreview}
            period={period}
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Crashes FL511"
            crashes={crashesFL}
            crashesPreview={crashesFLPreview}
            period={period}
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CircleChartWidget
            data={[
              { label: 'System', value: percent(totalCrashes(crashesSystemPreview), totalCrashes(crashesFLPreview) - totalCrashes(crashesSystemPreview)), icon: faCashRegister },
              { label: 'FL511', value: percent(totalCrashes(crashesFLPreview) - totalCrashes(crashesSystemPreview), totalCrashes(crashesSystemPreview)), icon: faCashRegister }
            ]}
            title="Crashes Comparison"
            className="circle-chart"
          />
        </Col>

        <Col xs={12} className="mb-4 d-sm-block">
          <ComparisonGraph
            crashesSystemPreview={crashesSystemPreview}
            crashesFLPreview={crashesFLPreview}
            period={period}
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center overviewGraph">
        <Col xs={12} className="mb-4 d-none d-sm-block">
            <Divider title={"System Usage"}/>
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <HalfCircleChart
            title="RAM Usage"
            data={serverStatus ? (serverStatus.length ? serverStatus[0].ram_usage : []) : []}
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <HalfCircleChart
            data={serverStatus ? (serverStatus.length ? serverStatus[0].cpu_usage : []) : []}
            title="CPU Usage"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <HalfCircleChart
            data={serverStatus ? (serverStatus.length ? serverStatus[0].disk_usage : []) : []}
            title="Disk Usage"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <HalfCircleChart
            data={serverStatus ? (serverStatus.length ? serverStatus[0].download_speed : []) : []}
            title="Download Speed"
          />
        </Col>

        <Col xs={12} className="mb-4 d-sm-block">
          <CameraCountGraph
            data={serverStatus}
            period={period}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-block">
          <SystemComparisonGraph
            data={serverStatus}
            period={period}
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <Divider title={"Error"}/>
        </Col>
        <ErrorCard
          errorStats={errorStats}
        />
        <ErrorGraph
          errorStats={errorStats}
          period={period}
        />
      </Row>
    </>
  );
}

function validateData(data, period) {
  const today = new Date();
  const validateData = [];

  if (period === 999) {
    const lastDate = new Date(data[0]['date']);
    period = Math.round((today - lastDate) / (1000 * 60 * 60 * 24));
  }

  for (let i = 0; i < period; i++) {
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    let dateString = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    validateData.push(data.find(item => item.date === dateString) || { date: dateString, count: 0 });
  }
  return validateData;
}

function totalCrashes(data) {
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    total += parseInt(data[i].count);
  }
  return total;
}

function percent(data1, data2) {
  return parseInt((data1 / (data2 + data1)) * 100);
}
