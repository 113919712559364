import React, { useMemo, useState, useRef, useEffect } from "react";

import { Dropdown, Input, Checkbox } from 'semantic-ui-react'
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import './ssogis.css';

export default (props) => {
	const [dropdownMultipleExpand, setDropdownMultipleExpand] = useState(false);

	const debouncedChangeHandler = useMemo(() => debounce(props.newSearch, 1000), [props.newSearch]);

	const severityOptions = [
		// { key: 0, text: '0: Unknown', value: 0, altText: 'Unknown', color: 'gray-c' },
		{ key: 1, text: '1: No Injury', value: 1, altText: 'No Inj', color: 'gray-c' },
		{ key: 2, text: '2: Possible Injury', value: 2 , altText: 'Poss Inj', color: 'green-c'},
		{ key: 3, text: '3: No-Incapacitating Injury', value: 3, altText: 'No-Incap', color: 'green-c' },
		{ key: 4, text: '4: Incapacitating Injury', value: 4, altText: 'Incap', color: 'blue-c' },
		{ key: 5, text: '5: Fatal (Within 30 Days) Injury', value: 5, altText: 'Fatal', color: 'red-c' },
		{ key: 6, text: '6: Non-Traffic Fatality', value: 6, altText: 'Non-Traffic Fat', color: 'red-c' },
	];

	const checkedOptions = [
		{ key: "not_checked", text: 'Not Checked', value: "not checked" },
		{ key: "no_crash", text: 'No Crash', value: "no crash" },
		{ key: "possible_crash", text: 'Possible Crash', value: "possible crash" },
		{ key: "confirmed_crash", text: 'Confirmed Crash', value: "confirmed crash" },
		{ key: "being_checked", text: 'Being Checked', value: "being checked" },
	];

	const dropdownMultipleRef = useRef(null);

	useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownMultipleRef.current && !dropdownMultipleRef.current.contains(event.target)) {
        setDropdownMultipleExpand(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

	return(
		<div className="ssogis-options-wrapper">
			<div className="ssogis-options">			
				<div className="dropDownWrapper" ref={dropdownMultipleRef}>
					<div className="">
						<button
							type="button"
							className="dropDownButton"
							id="options-menu"
							aria-haspopup="true"
							aria-expanded="true"
							onClick={() => setDropdownMultipleExpand(!dropdownMultipleExpand)}
						>
							Select Severity
							<div className="dropDownIcon">
								<FontAwesomeIcon icon={faCaretDown} size="sm" />
							</div>
						</button>
					</div>
					{dropdownMultipleExpand && (
						<div className="dropDownOptions">
							<div
								className="py-1"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="options-menu"
							>
								{severityOptions.map((element) => (
									<div
										key={severityOptions.key}
										role="menuitem"
										className="dropDownOptionsItemWrapper"
										onClick={() => {
											if (props.options.severity.includes(element.value)) {
												props.setOptions({
													...props.options,
													severity: props.options.severity.filter(
														(item) => item !== element.value
													),
												});
											} else {
												props.setOptions({
													...props.options,
													severity: [...props.options.severity, element.value],
												});
											}
											debouncedChangeHandler();
										}}
									>
									<div className="dropDownOptionsItem">
										<div className="">
											{props.options.severity.includes(element.value) ? (
												<FontAwesomeIcon icon={faCheckSquare}
													size="lg"
													color="blue"
												/>
											) : (
												<FontAwesomeIcon icon={faSquare}
													size="lg"
													color="gray"
												/>
											)}
										</div>
										<div className="ml-3">{element.text}</div>
									</div>
								</div>
								))}
							</div>
						</div>
					)}
				</div>

				<Dropdown
					className="dropDownWrapperChecked"
					placeholder='Checked'
					fluid
					search
					selection
					value={props.options.checked}
					options={checkedOptions}
					onChange={(e, { value }) => {
						props.setOptions({
							...props.options,
							checked: value
						});
						debouncedChangeHandler();
					}}
					clearable
				/>

				<Input
					placeholder='ID'
					value={props.options.id}
					onChange={(e, { value }) => {
						props.setOptions({
							...props.options,
							id: value
						});
						debouncedChangeHandler();
					}}
				/>

				<Checkbox
					label='Commercial'
					className="ssogis-checkbox"
					onChange={(e, { checked }) => {
						props.setOptions({
							...props.options,
							commercial: checked
						});
						debouncedChangeHandler();
					}}
					checked={props.options.commercial ? true : false}
				/>
			</div>
			<div className="ssogis-options-selected">
				{props.options.severity.sort((a, b) => a - b).map((element) => {
					const severityOption = severityOptions.find((item) => item.value === element);
					return (
						<div className={`ssogis-options-selected-item ${severityOption.color}`} key={element}
							onClick={() => {
								props.setOptions({
									...props.options,
									severity: props.options.severity.filter(
										(item) => item !== element
									),
								});
								debouncedChangeHandler();
							}}
						>
							<div className="ssogis-options-selected-item-text">
								{severityOption.altText}
							</div>
						<div className="ssogis-options-selected-item-count">
							{props.count[`severity_count_${element}`]}
						</div>
							<div className="ssogis-options-selected-item-cross">
								<FontAwesomeIcon icon={faTimes} size="sm" />
							</div>
						</div>
					);
				})}
				{props.options.checked && (
					<div className="ssogis-options-selected-item orange-c"
						onClick={() => {
							props.setOptions({
								...props.options,
								checked: "",
							});
							debouncedChangeHandler();
						}}
					>
						<div className="ssogis-options-selected-item-text">
							{props.options.checked}
						</div>
						<div className="ssogis-options-selected-item-count">
							{props.count[props.options.checked.toLowerCase().replace(" ", "_")]}
						</div>
						<div className="ssogis-options-selected-item-cross">
							<FontAwesomeIcon icon={faTimes} size="sm" />
						</div>
					</div>
				)}
				{props.options.commercial && (
					<div className="ssogis-options-selected-item lightblue-c"
						onClick={() => {
							props.setOptions({
								...props.options,
								commercial: "",
							});
							debouncedChangeHandler();
						}}
					>
						<div className="ssogis-options-selected-item-text">
							Commercial
						</div>
						<div className="ssogis-options-selected-item-count">
							{props.count.commercial_count}
						</div>
						<div className="ssogis-options-selected-item-cross">
							<FontAwesomeIcon icon={faTimes} size="sm" />
						</div>
					</div>
				)}
				{props.options.id !== 0 && props.options.id !== "" && (
					<div className="ssogis-options-selected-item gray-c"
						onClick={() => {
							props.setOptions({
								...props.options,
								id: "",
							});
							debouncedChangeHandler();
						}}
					>
						<div className="ssogis-options-selected-item-text">
							{props.options.id.toString().length > 7
								? props.options.id.toString().slice(0, 7) + "..."
								: props.options.id
							}
						</div>
						<div className="ssogis-options-selected-item-cross">
							<FontAwesomeIcon icon={faTimes} size="sm" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}