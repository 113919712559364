import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const ComparisonGraph = (props) => {
  return (
    <Card className="shadow-sm" style={{width: "100%", backgroundColor: "white"}}>
      <Card.Header className="d-flex flex-row align-items-center flex-0" style={{textAlign: "left"}}>
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            Crashes System vs FL511
          </h5>
          <small className="fw-bold mt-2">
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#1B9A92"}/> System</span>
            <span className="me-2"><FontAwesomeIcon icon={faSquare} color={"#2BADD2"}/> FL511</span>
          </small>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart crashesSystem={props.crashesSystemPreview} crashesFL={dataSplitter(props.crashesSystemPreview, props.crashesFLPreview)} />
      </Card.Body>
    </Card>
  );
};

const SalesValueChart = (props) => {

  const data = {
    labels: props.crashesSystem.map(item => item.date).reverse(),
    series: [props.crashesSystem.map(item => item.count).reverse(), props.crashesFL.map(item => item.count).reverse()]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: false,
    axisX: {
      position: 'left',
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return index % (props.crashesSystem.length / 10) < 1 ? dateFixer(value) : null;
      }
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: true,
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-double-octave" />
  );
};

function dateFixer(date) {
  return date.split("-").slice(1, 3).join("/");
}

function dataSplitter(data, data2) {
  if (data.length === 0 || data2.length === 0 || data.length >= data2.length) {
    return data2;
  }

  const returnData = [];
  for (let i = 0; i < data.length; i++) {
    returnData.push({
      date: data2[i].date,
      count: data2[i].count,
    });
  }

  return returnData;
}
