import React, { useEffect, useState } from 'react';
import '../records/pagination.css';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

import TableError from './tableError';
import TableServer from './tableServer';
import Pagination from '../records/pagination';
import Loading from '../records/loading';


export default (props) => {
  const itemsPerPage = 15;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);  
  const [itemOffset, setItemOffset] = useState(0);
  const [recordsLength, setRecordsLength] = useState(0);
  const [tableType, setTableType] = useState(true);

  useEffect(() => {    
    var params = new URLSearchParams();

    params.append('offset', itemOffset);
    params.append('limit', itemsPerPage);

    trackPromise(
      axios.post(props.endPoint, params).then(response => {
        if (response.data.count !== recordsLength) {
          setPageCount(Math.ceil(response.data.count / itemsPerPage));
          setRecordsLength(response.data.count);
        }
        setCurrentItems(response.data.crashes);
      }).catch(response => {
        console.log(response);
      })
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, props.endPoint]);

  useEffect(() => {
    setCurrentItems([]);
    setTableType(props.error);
    setItemOffset(0);
    setCurrPage(0);
  }, [props.error]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % recordsLength;
    setCurrPage(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <div className="pagination-list pagination-dashboard">
      {tableType ? <TableError data={currentItems} /> : <TableServer data={currentItems} /> }
      <Loading />
      <Pagination
        pageCount={pageCount}
        currPage={currPage}
        handlePageClick={handlePageClick}
        itemOffset={itemOffset}
        itemsPerPage={itemsPerPage}
        recordsLength={recordsLength}
        currentItems={currentItems}
      />
    </div>
  );
};
