import React from 'react';
import { useTable, useExpanded } from 'react-table'

function TableMobile({ columns: userColumns, data }) {
  const {
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded
    )
  
  return (
    <>
      <table className='secondary-table'>
        <thead>
          <tr>
            <th className='expand-header'></th>
            <th>County</th>
            <th>Region</th>
            <th>Roadway Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
					{rows.length > 0 ? rows.map((row) =>  {
						prepareRow(row);
						return (
							<React.Fragment>
								<tr key={`${row.id}-3`}>
									{row.cells.map(cell => {
										if(cell.column.Header === 'Date') {
											return (
												<td {...cell.getCellProps()}>{cell.value.slice(0, 16)}</td>
											)
										}
										else if(cell.column.Header !== "Description" && cell.column.Header !== "Direction") {
											return (
												<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											)
										}
										else {
											return (<></>);
										}
									})}
								</tr>
								{row.isExpanded ? (
									<tr key={`${row.id}-2`} className="expand-content">
										<td colSpan={visibleColumns.length}>
											<h4 className="case_id-expand">Crash ID</h4>
											<p>{row.original.crash_id}</p>
											<h4>Description</h4>
											<p className="description-expand">{row.original.description}</p>
											<h4>Direction</h4>
											<p className="direction-expand">{row.original.direction}</p>
										</td>
									</tr>
								) : null}
							</React.Fragment>
					)
			}) : <tr><td>No data</td></tr>}
        </tbody>
      </table>
      <br />
    </>
  )
}

export default TableMobile;